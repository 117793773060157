import api from "@api";
import router from "./../../../../router/index";

export default {
    state: {
        activities: [],
        allActivities: [],
        commercialActivities: [],
        activitiesDto: {
            accountName: "",
            accountVerification: "",
            commerialActivityId: "",
            password: "",
            countryId: "",
            cityId: "",
        },
        activitiesDetailsDto: {
            accountId: "",
            name: "",
            imageProfile: "",
            rate: "",
            bio: "",
            phoneNumber: "",
            email: "",
            accountVerification: "",
            location: "",
            dateCreate: "",
            commercialActivityId: "",
            commercialActivityName: "",
            file: null,
            removeFile: false,
            commnets: [
                {
                    commenterId: "",
                    commenterName: "",
                    profileImage: "",
                    comment: "",
                    date: "",
                },
            ],
            followers: [
                {
                    accountId: "",
                    name: "",
                    date: "",
                },
            ],
            visitors: [],
            socialMedia: [
                {
                    title: "",
                    content: "",
                },
            ],
            cityId: "",
            countryId: "",
        },
        activityForm: {
            accountName: "",
            accountVerification: "",
            commerialActivityId: "",
            password: "",
            countryId: "",
            cityId: "",
        },

        activityOrdersTable: {
            notifications: [],
            services: [],
            jobs: [],
            subscriptions: [],
        },
        activitySubscriptionList: [
            { number: 1, name: "فعال", color: "success" },
            { number: 2, name: "منتهي", color: "secondary" },
        ],
        activeActivityOrderTab: 0,
        earningChartData: {
            chartOptions: {
                chart: {
                    type: "donut",
                    width: "100px",
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                legend: { show: false },
                comparedResult: [2, -3, 8],
                labels: ["App", "Service", "Product"],
                stroke: { width: 0 },
                colors: ["blue", "#B9B9C3"],
                grid: {
                    padding: {
                        right: -20,
                        bottom: -8,
                        left: -20,
                    },
                },
                plotOptions: {
                    pie: {
                        startAngle: 0,
                        donut: {
                            labels: {
                                show: true,
                                name: {
                                    show: false,
                                    offsetY: 100,
                                },
                                value: {
                                    offsetY: 3.5,
                                    formatter(val) {
                                        // eslint-disable-next-line radix
                                        return `${parseInt(val)}`;
                                    },
                                },
                                total: {
                                    show: true,
                                    offsetY: 15,
                                    label: "App",
                                    formatter() {
                                        return "53%";
                                    },
                                },
                            },
                        },
                    },
                },
                responsive: [
                    {
                        breakpoint: 1325,
                        options: {
                            chart: {
                                height: 100,
                            },
                        },
                    },
                    {
                        breakpoint: 1200,
                        options: {
                            chart: {
                                height: 120,
                            },
                        },
                    },
                    {
                        breakpoint: 1045,
                        options: {
                            chart: {
                                height: 100,
                            },
                        },
                    },
                    {
                        breakpoint: 992,
                        options: {
                            chart: {
                                height: 120,
                            },
                        },
                    },
                ],
            },
        },
    },
    getters: {
        activitySubscriptionOrders(state) {
            return state.activityOrdersTable.subscriptions;
        },
        activityNotificationOrders(state) {
            return state.activityOrdersTable.notifications;
        },
        activityAdsOrders(state) {
            return state.activityOrdersTable.services;
        },
        activityJobOrders(state) {
            return state.activityOrdersTable.jobs;
        },
        activityStats(state) {
            return {
                notifications: state.activityOrdersTable.notifications.length,
                jobs: state.activityOrdersTable.jobs.length,
                services: state.activityOrdersTable.services.length,
                subscriptions: state.activityOrdersTable.subscriptions.length,
                followers: state.activitiesDetailsDto.followers.length,
                visitors: state.activitiesDetailsDto.visitors.length,
            };
        },
    },
    mutations: {
        Get_Activities_List(state, payload) {
            state.allActivities = payload.map((item) => ({
                ...item,
                id: item.accountId,
            }));
        },
        Get_Activities_Base_Info(state, payload) {
            state.activities = payload;
        },
        Get_Activities_Details(state, payload) {
            state.activitiesDetailsDto = {
                ...payload,
                password: "",
                removeFile: false,
                isEmailActive: payload.email ? true : false,
                isPhoneNumberActivie: payload.phoneNumber ? true : false,
                tempMail: payload.email ? "" : payload.email,
                tempPhone: payload.phoneNumber ? "" : payload.phoneNumber,
            };
        },
        Update_Activity_Email_Is_Active(state, payload) {
            state.activitiesDetailsDto = {
                ...state.activitiesDetailsDto,
                isEmailActive: payload,
                tempMail: payload ? "" : state.activitiesDetailsDto.tempMail,
                tempPhone: payload ? "" : state.activitiesDetailsDto.tempPhone,
            };
            console.log(
                "Update_Activity_Email_Is_Active",
                state.activitiesDetailsDto
            );
        },
        Set_Commercial_Activities(state, payload) {
            state.commercialActivities = payload;
        },
        Set_Activity_Orders(state, payload) {
            state.activityOrdersTable = {
                ...payload,
                subscriptions: payload.subscriptions.map((item) => {
                    return {
                        ...item,
                        subStatuses:
                            new Date(item.endSub) >= Date.now() &&
                            new Date(item.startSub) <= Date.now()
                                ? 1
                                : 2,
                    };
                }),
            };
        },
        Update_Activities(state, payload) {
            state.activitiesDetailsDto = { ...payload, removeFile: false };
        },
        Update_Activity_Visits_Stats(state, payload) {
            let max = 0;

            state.activitiesDetailsDto.visitors.map((item) => {
                max = item.visitCount > max ? item.visitCount : max;
            });

            state.activitiesDetailsDto.visitors = [];

            for (let item of payload) {
                state.activitiesDetailsDto = {
                    ...state.activitiesDetailsDto,
                    visitors: [
                        ...state.activitiesDetailsDto.visitors,
                        {
                            ...item,
                            ...state.earningChartData,

                            series: [max - item.visitCount, max],
                            chartOptions: {
                                ...state.earningChartData.chartOptions,
                                chart: {
                                    ...state.earningChartData.chartOptions
                                        .chart,
                                    width: "50px",
                                },
                                colors: ["#B9B9C3", "#79AFEF"],
                                labels: ["عدد الزيارات", "عدد الزيارات الكلي"],
                                plotOptions: {
                                    ...state.earningChartData.chartOptions
                                        .plotOptions,
                                    pie: {
                                        ...state.earningChartData.chartOptions
                                            .plotOptions.pie,
                                        size: 60,
                                        donut: {
                                            ...state.earningChartData
                                                .chartOptions.plotOptions.pie
                                                .donut,
                                            labels: {
                                                ...state.earningChartData
                                                    .chartOptions.plotOptions
                                                    .pie.donut.labels,
                                                name: {
                                                    ...state.earningChartData
                                                        .chartOptions
                                                        .plotOptions.pie.donut
                                                        .labels.name,
                                                    fontSize: "0.9rem",
                                                },
                                                value: {
                                                    ...state.earningChartData
                                                        .chartOptions
                                                        .plotOptions.pie.donut
                                                        .labels.value,
                                                    fontSize: "0.9rem",
                                                },
                                                total: {
                                                    ...state.earningChartData
                                                        .chartOptions
                                                        .plotOptions.pie.donut
                                                        .labels.total,
                                                    show: true,
                                                    label: "",
                                                    formatter: () => max,
                                                },
                                            },
                                        },
                                    },
                                },
                                responsive: [
                                    {
                                        breakpoint: 1800,
                                        options: {
                                            chart: {
                                                width: 40,
                                                height: 40,
                                            },
                                        },
                                    },
                                    {
                                        breakpoint: 1325,
                                        options: {
                                            chart: {
                                                width: 40,
                                                height: 40,
                                            },
                                        },
                                    },
                                    {
                                        breakpoint: 1200,
                                        options: {
                                            chart: {
                                                width: 40,
                                                height: 40,
                                            },
                                        },
                                    },
                                    {
                                        breakpoint: 1045,
                                        options: {
                                            chart: {
                                                width: 40,
                                                height: 40,
                                            },
                                        },
                                    },
                                    {
                                        breakpoint: 992,
                                        options: {
                                            chart: {
                                                width: 40,
                                                height: 40,
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                    ],
                };
            }
        },
        Add_Activities(state, payload) {
            state.allActivities.unshift(payload);
            state.allActivities = state.allActivities.map((item) => {
                return item.accountId == payload.accountId
                    ? { ...item, id: item.accountId }
                    : { ...item };
            });
        },
        Delete_Activity(state, accountId) {
            state.activities.splice(
                state.activities.findIndex(
                    (item) => item.accountId == accountId
                ),
                1
            );
        },
        Delete_Activities_List(state, payload) {
            state.allActivities = state.allActivities.filter(
                (el) => !payload.includes(el.accountId)
            );
        },
        Reset_Activities_Dto(state) {
            Object.assign(state.activiteDto, {
                name: "",
                emailOrPhoneNumber: "",
                commerialActivityId: "",
                password: "",
                countryId: "",
                cityId: "",
            });
        },
        Reset_Activity_Form(state) {
            state.activityForm = {
                accountName: "",
                accountVerification: "",
                commerialActivityId: "",
                password: "",
                countryId: "",
                cityId: "",
            };
        },
        Set_Active_Activity_Order_Tab(state, payload) {
            state.activeActivityOrderTab = payload;
        },
    },
    actions: {
        getActivitiesList({ commit }) {
            api.get("Activity/GetAllDash?lang=" + "Ar", ({ data }) => {
                commit("Get_Activities_List", data);
            });
        },
        getActivtitiesBaseInfo({ commit }) {
            api.get("Activity/GetBaseInfoDash", ({ data }) => {
                commit("Get_Activities_Base_Info", data);
            });
        },
        getCommercialActivities({ commit }) {
            api.get("CommercialActivity/GetAll", ({ data }) => {
                commit("Set_Commercial_Activities", data);
            });
        },
        getActivityOrders({ commit }, payload) {
            api.get(
                `Activity/GetOtherInfoDash?accountId=${payload}`,
                ({ data }) => {
                    commit("Set_Activity_Orders", data);
                }
            );
        },
        getActivitiesDetails({ commit }, id) {
            api.get(`Activity/GetByIdDash?id=${id}`, ({ data }) => {
                commit("Get_Activities_Details", data);
                commit("Update_Activity_Visits_Stats", data.visitors);
            });
        },
        addActivities({ commit }, payload) {
            api.post(
                "Activity/AddDash",
                payload,
                ({ data }) => {
                    console.log("data", data);
                    commit("Add_Activities", data);
                    commit("Reset_Activity_Form");
                },
                {
                    success: "تم إضافة النشاط بنجاح",
                    error: "فشل إضافة النشاط",
                }
            );
        },
        updateActivities(ctx, payload) {
            let formData = new FormData();

            formData.append("accountId", payload.accountId);
            formData.append("name", payload.name);
            formData.append(
                "commercialActivityId",
                payload.commercialActivityId
            );
            formData.append("accountVerification", payload.accountVerification);
            formData.append("email", payload.email);
            formData.append("password", payload.password);
            formData.append("userName", payload.userName);
            formData.append("countryId", payload.countryId);
            formData.append("cityId", payload.cityId);
            formData.append("file", payload.file);
            formData.append("removeFile", payload.removeFile);

            api.post(
                "Activity/ModifyDash",
                formData,
                ({ data }) => {
                    this.commit("Update_Activities", data);
                },
                {
                    success: "تم تعديل النشاط بنجاح",
                    error: "فشل تعديل النشاط",
                }
            );
        },
        deleteActivity({ commit }, id) {
            api.delete(
                `Activity/DeleteDash?id=${id}`,
                () => {
                    commit("Delete_Activity", id);
                    router.push("/admin/activities/");
                },
                {
                    confirm: "هل تريد فعلاً حذف الفعالية ",
                    success: "تم حذف الفعالية بنجاح",
                    error: "فشل حذف الفعالية",
                }
            );
        },
        deleteActivitesList({ commit }, ids) {
            api.delete(
                "Activity/DeleteRangDash",
                () => {
                    commit("Delete_Activities_List", ids);
                },
                {
                    confirm: "هل تريد فعلاً حذف الفعاليات المحددةٍ",
                    success: "تم حذف الفعاليات المحددة بنجاح",
                    error: "فشل حذف الفعاليات المحددة",
                },
                ids
            );
        },
    },
};
