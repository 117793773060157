import accounts from "@admin/accounts";
import home from "@admin/home";
import adsAndOffers from "@admin/adsAndOffers";
import notifications from "@admin/notifications";
import activities from "@admin/activities";
import jobs from "@admin/jobs";
import orders from "@admin/orders";
import subscriptions from "@admin/subscriptions";
import locations from "@admin/locations";
import users from "@admin/users"
import contactUs from "@admin/contactUs"
import settings from "@admin/settings"
import commercialActivities from "@admin/commercialActivities"
import reports from "@admin/reports"

export default {
    home,
    contactUs,
    accounts,
    adsAndOffers,
    notifications,
    activities,
    jobs,
    orders,
    subscriptions,
    locations,
    users,
    settings,
    commercialActivities,
    reports
};
