import api from "@api";

export default {
    state: {
        commercialActivities: [],
        commercialActivityDto: {
            name: "",
        },
    },
    mutations: {
        Set_Commercial_Activity_List(state, payload) {
            state.commercialActivities = [...payload];
        },
        Delete_Commercial_Activity(state, payload) {
            state.commercialActivities = state.commercialActivities.filter(
                (item) => item.id != payload
            );
        },
        Add_Commercial_Activity(state, payload) {
            state.commercialActivities = [
                { ...payload },
                ...state.commercialActivities,
            ];
        },
        Reset_Commercial_Activity(state) {
            state.commercialActivityDto = {
                name: "",
            };
        },
    },
    actions: {
        getCommercialActivities({ commit }) {
            api.get("CommercialActivity/GetAllDash", ({ data }) => {
                commit("Set_Commercial_Activity_List", data);
            });
        },

        addCommercialActivity({ commit }, payload) {
            api.post(
                "CommercialActivity/addDash",
                payload,
                ({ data }) => {
                    commit("Add_Commercial_Activity", data);
                },
                {
                    success: "تمت إضافة النشاط التجاري بنجاح",
                    error: "فشلت الإضافة .. يرجى إعادة المحاولة",
                }
            );
        },
        deleteCommercialActivity({ commit }, id) {
            api.delete(
                `CommercialActivity/DeleteDash?id=${id}`,
                () => {
                    commit("Delete_Commercial_Activity", id);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف النشاط التجاري",
                    success: "تم حذف النشاط التجاري بنجاح",
                    error: "فشل حذف النشاط التجاري ..يرجى اعادة المحاولة ",
                }
            );
        },
    },
};
