export default {
    // Endpoints
    websiteLoginEndpoint: "Account/signIn",
    loginEndpoint: "/DashUser/SignIn",
    registerEndpoint: "/jwt/register",
    refreshEndpoint: "Account/RefrshToken",
    logoutEndpoint: "/jwt/logout",

    // This will be prefixed in authorization header with token
    // e.g. Authorization: Bearer <token>
    tokenType: "Bearer",

    // Value of this property will be used as key to store JWT token in storage
    storageTokenKeyName: "meMateAccessToken",
    storageRefreshTokenKeyName: "meMateRefreshToken",

    websiteStorageTokenKeyName: "meMateWebsiteAccessToken",
};
