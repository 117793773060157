import api from "@api";
import router from "@/router";
export default {
    state: {
        users: [],
        userDto: {
            userName: "",
            birthDate: "",
            id: "",
            dateCreated: "",
            isBlocked: false,
            name: "",
            email: "",
            phoneNumber: null,
            role: 0,
            password: "",
        },
        accountTypes: [
            { name: "فعاليات", id: 1 },
            { name: "مستخدمون", id: 2 },
            { name: "الجميع", id: 4 },
        ],
    },
    mutations: {
        Set_Users_List(state, payload) {
            state.users = payload;
        },
        Set_User_Dto(state, payload) {
            if (payload) Object.assign(state.userDto, payload);
            else
                state.userDto = {
                    userName: "",
                    birthDate: "",
                    id: "",
                    dateCreated: "",
                    isBlocked: false,
                    name: "",
                    email: "",
                    phoneNumber: null,
                    role: 0,
                    password: "",
                };
        },
        Reset_User_Dto(state) {
            state.userDto = {
                userName: "",
                birthDate: "",
                id: "",
                dateCreated: "",
                isBlocked: false,
                name: "",
                email: "",
                phoneNumber: null,
                role: 0,
                password: "",
            }
        },
        Add_Account(state, payload) {
            state.users.push(payload);
        },
        Set_Account_Block(state, payload) {
            state.userDto.isBlocked = payload;
        },
        Delete_Account() {
            router.push("/admin/accounts");
        },
        Delete_Accounts(state, payload) {
            state.users = state.users.filter((el) => !payload.includes(el.id));
        },
    },
    actions: {
        getDashUsers({ commit }) {
            api.get("DashUser/GetAll", ({ data }) => {
                commit("Set_Users_List", data);
            });
        },
        getUserDetails({ commit }, id) {
            api.get(`DashUser/GetById?id=${id}`, ({ data }) => {
                commit("Set_User_Dto", data);
            });
        },
        addDashUser({ commit }, payload) {
            payload.role += 1
            
            let fd = new FormData();
            for (const key in payload) {
                fd.append(key, payload[key]);
            }
            api.post(
                "DashUser/add",
                fd,
                (res) => {
                    commit("Add_Account", res.data);
                    commit("Reset_User_Dto")

                },
                {
                    success: "تمت إضافة المستخدم بنجاح",
                    error: "فشلت الإضافة .. يرجى إعادة المحاولة",
                }
            );
        },
        updateAccount({ commit }, payload) {
            let fd = new FormData();
            for (const key in payload) {
                fd.append(key, payload[key]);
            }

            api.post(
                "DashUser/Modify",
                fd,
                (res) => {
                    commit("Set_User_Dto", res.data);
                    router.push("/admin/accounts");
                },
                {
                    confirm: "هل تريد بالتأكيد تعديل معلومات المستخدم؟",
                    success: "تم تعديل المستخدم بنجاح",
                    error: "فشل التعديل.. يرجى إعادة المحاولة",
                }
            );
        },
        blockAccount({ commit }, id) {
            api.post(
                `DashUser/BlockAccount?userId=${id}`,
                null,
                (res) => {
                    if (res.status == 200) commit("Set_Account_Block", true);
                },
                {
                    success: "تم حظر المستخدم بنجاح",
                    error: "فشل حظر المستخدم ..يرجى اعادة المحاولة ",
                }
            );
        },
        unBlockAccount({ commit }, id) {
            console.log("unvblock");
            api.post(
                `DashUser/UnBlockAccount?userId=${id}`,
                null,
                () => {
                    commit("Set_Account_Block", false);
                },
                {
                    success: "تم الغاء حظر المستخدم بنجاح",
                    error: "فشل الغاء الحظر  ..يرجى اعادة المحاولة ",
                }
            );
        },
        deleteAccount({ commit }, id) {
            api.delete(
                `DashUser/Delete?id=${id}`,
                () => {
                    commit("Delete_Account", id);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف المستخدم",
                    success: "تم حذف المستخدم بنجاح",
                    error: "فشل حذف المستخدم ..يرجى اعادة المحاولة ",
                }
            );
        },
        deleteAccounts({ commit }, ids) {
            api.delete(
                "DashUser/DeleteRang",
                () => {
                    commit("Delete_Accounts", ids);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف الحسابات المحددة",
                    success: "تم حذف الحسابات بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                ids
            );
        },
    },
};
