import Vue from "vue";
import router from "./router";
import bootstrapVue from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import VueFlags from "@growthbunker/vueflags";
import "@/assets/scss/components/_av_table.scss";
import store from "./store";
import i18n from "./libs/i18n/index";
import App from "./App.vue";
import numeral from "numeral";
import numFormat from "vue-filter-number-format";

import AOS from "aos";
import "aos/dist/aos.css";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";
import VueFormulate from "@braid/vue-formulate";
import "@braid/vue-formulate/themes/snow/snow.scss";

Vue.use(VueFormulate);
// BSV Plugin Registration
Vue.use(bootstrapVue);

// Composition API
Vue.use(VueCompositionAPI);

// flags
Vue.use(VueFlags, {
    iconPath: "/media/images/flags"
});
// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");
require("@core/scss/vue/libs/vue-select.scss");

Vue.config.productionTip = false;

var formatDate = function(date) {
    return date == null
        ? "-"
        : new Date(date).toLocaleDateString("en-UK", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric"
          });
};
Vue.filter("formatDate", formatDate);

Vue.filter("numFormat", numFormat(numeral));

var filter = function(text, length, clamp) {
    clamp = clamp || "...";
    var node = document.createElement("div");
    node.innerHTML = text;
    var content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter("truncate", filter);
Vue.use(i18n);
new Vue({
    created() {
        AOS.init("body");
    },
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount("#app");
