<template>
  <div>
    <Slider v-bind="$attrs" v-model="innerVal" />
  </div>
</template>

<script>
import Slider from "@vueform/slider/dist/slider.vue2.js";
import "@vueform/slider/themes/default.css";
export default {
  props: {
    value: null,
  },
  components: {
    Slider,
  },
  data() {
    return {
      innerVal: 0,
    };
  },

  mounted() {
    if (this.value) {
      console.log("this.value :>> ", this.value);
      this.innerVal = this.value;
    }
  },
  watch: {
    value(nv) {
      this.innerVal = nv;
    },
    innerVal(nv) {
      this.$emit("input", nv);
    },
  },
};
</script>

<style lang="scss">
.slider-connect {
  background: var(--primary) !important;
  cursor: pointer;
}
.slider-tooltip {
  background: var(--primary) !important;
  border: var(--primary);
  cursor: pointer;
}
</style>
