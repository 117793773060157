import { getSearchResultOnRow } from "@Ekcore/util";
export default {
    state: {
        filterDto: {},
        searchDto: {
            keys: [],
            query: "",
        },
        tempFiles: [],
    },
    getters: {
        AccountsList(state, getter, glState) {
            return glState.accounts.users.filter((serv) => {
                return getSearchResultOnRow(state, serv);
            });
        },
        AppUserList(state, getter, glState) {
            return glState.subscriptions.usersList.filter((serv) => {
                return getSearchResultOnRow(state, serv);
            });
        },
        SubActivityList(state, getter, glState) {
            return glState.subscriptions.activitiesList.filter((serv) => {
                return getSearchResultOnRow(state, serv);
            });
        },
        notificationsList(state, getter, glState) {
            return glState.notifications.notifications.filter((serv) => {
                return getSearchResultOnRow(state, serv);
            });
        },
        subscripeActivityList(state, getter, glState) {
            return glState.subscriptions.subsActivity.filter((serv) => {
                return getSearchResultOnRow(state, serv);
            });
        },
        // subscripeAppUserList(state, getter, glState) {
        //     return glState.subscriptions.subscripesAppUser.filter((serv) => {
        //         return getSearchResultOnRow(state, serv);
        //     });
        // },
        activitesList(state, getter, glState) {
            return glState.activities.allActivities.filter((serv) => {
                return getSearchResultOnRow(state, serv);
            });
        },
        adsList(state, getter, glState) {
            console.log("updated");
            return glState.adsAndOffers.ads.filter((ad) => {
                return (
                    getSearchResultOnRow(state, ad) &&
                    (ad.senderId == state.filterDto.activityId ||
                        !state.filterDto.activityId)
                );
            });
        },
        usersList(state, getter, glState) {
            return glState.users.users.filter((serv) => {
                return getSearchResultOnRow(state, serv);
            });
        },
        todayOrdersList(state, getter, glState) {
            return glState.orders.orders.allRequests.filter((serv) => {
                return getSearchResultOnRow(state, serv);
            });
        },
        notificationOrdersList(state, getter, glState) {
            return glState.orders.notificationOrders.filter((serv) => {
                return getSearchResultOnRow(state, serv);
            });
        },
        adsOrdersList(state, getter, glState) {
            return glState.orders.adsOrders.filter((serv) => {
                return getSearchResultOnRow(state, serv);
            });
        },
        jobOrdersList(state, getter, glState) {
            return glState.orders.jobOrders.filter((serv) => {
                return getSearchResultOnRow(state, serv);
            });
        },
        questionsList(state, getter, glState) {
            return glState.settings.questions.filter((serv) => {
                return getSearchResultOnRow(state, serv);
            });
        },
        commercialActivitiesList(state, getter, glState) {
            return glState.commercialActivities.commercialActivities.filter(
                (serv) => {
                    return getSearchResultOnRow(state, serv);
                }
            );
        },
        userReportsList(state, getter, glState) {
            return glState.reports.userReports.filter(
                (serv) => {
                    return getSearchResultOnRow(state, serv);
                }
            );
        },
        activityReportsList(state, getter, glState) {
            return glState.reports.activityReports.filter(
                (serv) => {
                    return getSearchResultOnRow(state, serv);
                }
            );
        },
    },
    mutations: {
        Set_Filter_Dto(state, payload) {
            Object.keys(payload).forEach((key) => {
                state.filterDto[key] = payload[key];
            });
        },
        Set_Search_Dto(state, payload) {
            Object.assign(state.searchDto, payload);
        },
        Reset_Search_Dto(state) {
            Object.assign(state.searchDto, {
                keys: [],
                query: "",
            });
        },
        Reset_filter_Dto(state) {
            Object.assign(state.filterDto, {});
        },
        Update_Dto_Files(state, payload) {
            state.tempFiles = { ...payload.dto, files: payload.imagesList };
        },
        Reset_Temp_Files(state) {
            state.tempFiles = [];
        },
    },
};
