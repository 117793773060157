import api from "@api";
import router from "@/router";
import { nullGuid } from "@Ekcore/util/global.js";
import { getSearchResultOnRow } from "@Ekcore/util";

export default {
    state: {
        contacts: [],
        contactDto: {
            id: nullGuid,
            title: "",
            content: "",
            senderName:"" ,
            phoneNumber:"" ,
            contactDate: "",
            response: "",
            isResponsed:""
        },
        filterDto: {},
        searchDto: {
            keys: [],
            query: "",
        },
        tempFiles: [],
    },
    getters:{
        contactList(state) {
            return state.contacts.filter((serv) => {
                return getSearchResultOnRow(state, serv);
            });
        }
    },
    mutations: {
        Get_Contact_List(state, payload) {
            state.contacts = payload 
            
        },
        Reset_Contact_Dto(state) {
            Object.assign(state.contacts, {
                id: nullGuid,
                title: "",
                content: "",
                senderName:"" ,
                phoneNumber:"" ,
                contactDate: "",
                response: "",
                isResponsed:""
            });
        },
        Get_Details_Contact(state, payload) {
            state.contactDto = { ...payload };
        },
        Update_Contact(state, payload) {
            state.contactDto = { ...payload } 
        },
        Delete_Contact(state, id) {
            state.contacts.splice(
                state.contacts.findIndex((item) => item.id == id),
                1
            );
        },
        Delete_Contacts(state, ids) {
            state.contacts = state.contacts.filter(
                (el) => !ids.includes(el.id)
            ); 
        },
        Set_Contact_Dto(state, payload) {
            if (payload) Object.assign(state.contactDto, payload);
            else {
                state.contactDto = {
                    id: nullGuid,
                    title: "",
                    content: "",
                    senderName:"" ,
                    phoneNumber:"" ,
                    contactDate: "",
                    response: "",
                };
            }
        },
    },
    actions: {
        getContactsList({ commit }) {
            api.get("Setting/GetAllContactUsDash", ({ data }) => {
                commit("Get_Contact_List", data);
                this.commit("Set_Contact_Dto");
            });
        },
        getContactDetails({ commit }, id) {
            api.get(`Setting/GetContactUsByIdDash?id=${id}`, ({ data }) => {
                commit("Get_Details_Contact", data);
            });
        },
        updateContact({ commit }, payload) {
            api.post(
                "Setting/ResponseContactUsDash",
                payload,
                ({ data }) => {
                    commit("Update_Contact", data);
                    router.push("/admin/contactUs");
                },
                {
                    success: "تم الرد  بنجاح",
                    error: "فشل الرد ",
                }
            );
        },
        deleteContact({ commit }, id) {
            api.delete(
                `Setting/DeleteContactUsDash?id=${id}`,
                () => {
                    commit("Delete_Contact", id);
                    router.push("/admin/contactUs");
                },
                {
                    confirm: " هل تريد بالتأكيد حذف الرسالة",
                    success: "تم حذف الرسالة بنجاح",
                    error: "فشل حذف الرسالة ..يرجى اعادة المحاولة ",
                }
            );
        },
        deleteContacts({ commit }, ids) {
            api.delete(
                "Setting/DeleteRangeContactUsDash",
                () => {
                    commit("Delete_Contacts", ids);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف الرسائل المحددة",
                    success: "تم حذف الرسائل بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                ids
            );
        },
    },
};