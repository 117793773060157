import api from "@api";
import router from "@/router";

export default {
    state: {
        users: [],
        comments: [],
        notifications: [],
        jobs: [],
        subscriptions: [],
        userConnects: {
            followings: [],
            visitings: [],
            visitors: [],
            followers: [],
        },
        userDto: {
            id: "",
            password: "",
        },
        userDetails: {
            accountStatuses: 0,
            appUserStatus: 0,
            comments: [],
            contactInfo: {
                email: "",
                phoneNumber: "",
                gender: 1,
                birthDate: "",
            },
            id: "",
            name: "",
            email: "",
            newPassword: "",
            phoneNumber: "",
            dateCreate:"",
            removeImage: false,
            image: "",
            personalInfo: [],
            profileImage: [],
            socialMedias: [],
        },
        accountStatus: [
            { id: "1",number: 1, name: "فترة تجريبية ", color: "primary" },
            { id: "2",number: 2,  name: "مشترك", color: "success" },
            { id: "3", number: 3, name: "مجاني", color: "warning" },
        ],
        userJobStatus: [
            { id: 1, number: 1, name: "متاحة", color: "success" },
            { id: 2, number: 2, name: "غير متاحة", color: "warning" },
        ],
        addUserForm: {
            name: "",
            accountVerification: "",
            password: "",
            countryId: "",
            cityId: "",
        },
        activeUserOrderTab: 0,
        userJobDto: {
            submitInfo: {
                lastScientificDegree: "",
                graduationDate: null,
                schoolName: "",
                skills: "",
                languanges: null,
                cvUrl: "",
                socialMedias: [],
                contactInfo: {
                    email: "",
                    phoneNumber: "",
                    gender: null,
                    birthDate: null,
                },
            },
        },
        earningChartData: {
            chartOptions: {
                chart: {
                    type: "donut",
                    width: "100px",
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                legend: { show: false },
                comparedResult: [2, -3, 8],
                labels: ["App", "Service", "Product"],
                stroke: { width: 0 },
                colors: ["blue", "#B9B9C3"],
                grid: {
                    padding: {
                        right: -20,
                        bottom: -8,
                        left: -20,
                    },
                },
                plotOptions: {
                    pie: {
                        startAngle: 0,
                        donut: {
                            labels: {
                                show: true,
                                name: {
                                    show: false,
                                    offsetY: 100,
                                },
                                value: {
                                    offsetY: 3.5,
                                    formatter(val) {
                                        // eslint-disable-next-line radix
                                        return `${parseInt(val)}`;
                                    },
                                },
                                total: {
                                    show: true,
                                    offsetY: 15,
                                    label: "App",
                                    formatter() {
                                        return "53%";
                                    },
                                },
                            },
                        },
                    },
                },
                responsive: [
                    {
                        breakpoint: 1325,
                        options: {
                            chart: {
                                height: 100,
                            },
                        },
                    },
                    {
                        breakpoint: 1200,
                        options: {
                            chart: {
                                height: 120,
                            },
                        },
                    },
                    {
                        breakpoint: 1045,
                        options: {
                            chart: {
                                height: 100,
                            },
                        },
                    },
                    {
                        breakpoint: 992,
                        options: {
                            chart: {
                                height: 120,
                            },
                        },
                    },
                ],
            },
        },
    },

    mutations: {
        //User Details Page
        Set_User_Details(state, payload) {
            state.userDetails = { ...payload, newPassword:"" , removeImage: false };
            state.comments = [...payload.comments];
        },
        Set_User_Connects(state, payload) {
            state.userConnects = {
                ...payload,
                followings: payload.followings.map((item) => {
                    return {
                        ...item,
                        comments: item.comments?.map((item) => ({
                            ...item,
                            isToggled: false,
                        })),
                    };
                }),
            };
        },
        Set_User_About(state, payload) {
            state.notifications = [...payload.notifications];
            state.subscriptions = payload.subscriptions.map((item) => {
                return {
                    ...item,
                    subscriptionStatus:
                        new Date(item.endSub) >= Date.now() ? 1 : 2,
                };
            });
            state.jobs = [...payload.jobs];
        },
        Set_User_Job_Details(state, payload) {
            state.userJobDto = {
                ...payload,
                jobStatus: payload.isAvailable ? 1 : 2,
                deadLineDate: new Date(
                    payload.deadLineDate
                ).toLocaleDateString(),
            };
        },
        Delete_User() {
            router.push("/admin/users");
        },

        //Users Page
        Get_User_List(state, payload) {
            state.users = payload;
        },
        Update_User(state, payload) {
            state.userDetails = { ...payload, removeImage: false, isEmailActive: payload.contactInfo?.email ? true : false };
        },
        Update_User_Email_Is_Active(state, payload) {
            state.userDetails = { ...state.userDetails, isEmailActive: payload }
        },
        Add_User(state, payload) {
            state.users.unshift(payload);
        },
        Set_User_Dto(state) {
            state.addUserForm = {
                name: "",
                accountVerification: "",
                password: "",
                countryId: "",
                cityId: "",
            };
        },
        Delete_Users(state, payload) {
            state.users = state.users.filter((el) =>
                !payload.includes(el.accountId)
            );
        },
        Update_User_Orders_Active_Tab(state, payload) {
            state.activeUserOrderTab = payload;
        },
        Reset_User_Notification_Orders(state) {
            state.notifications = [];
        },
        Reset_User_Subscription_Orders(state) {
            state.subscriptions = [];
        },
        Reset_User_Job_Orders(state) {
            state.jobs = [];
        },
        Update_User_Visitings_Stats(state, payload) {
            let max = 0;

            state.userConnects.visitings.map((item) => {
                max = item.dateTimes.length > max ? item.dateTimes.length : max;
            });

            state.userConnects.visitings = [];

            for (let item of payload.visitings) {
                state.userConnects = {
                    ...state.userConnects,
                    visitings: [
                        ...state.userConnects.visitings,
                        {
                            ...item,
                            ...state.earningChartData,

                            series: [max - item.dateTimes.length, max],
                            chartOptions: {
                                ...state.earningChartData.chartOptions,
                                chart: {
                                    ...state.earningChartData.chartOptions
                                        .chart,
                                    width: "50px",
                                },
                                colors: ["#B9B9C3", "#79AFEF"],
                                labels: ["عدد الزيارات", "عدد الزيارات الكلي"],
                                plotOptions: {
                                    ...state.earningChartData.chartOptions
                                        .plotOptions,
                                    pie: {
                                        ...state.earningChartData.chartOptions
                                            .plotOptions.pie,
                                        size: 60,
                                        donut: {
                                            ...state.earningChartData
                                                .chartOptions.plotOptions.pie
                                                .donut,
                                            labels: {
                                                ...state.earningChartData
                                                    .chartOptions.plotOptions
                                                    .pie.donut.labels,
                                                name: {
                                                    ...state.earningChartData
                                                        .chartOptions
                                                        .plotOptions.pie.donut
                                                        .labels.name,
                                                    fontSize: "0.9rem",
                                                },
                                                value: {
                                                    ...state.earningChartData
                                                        .chartOptions
                                                        .plotOptions.pie.donut
                                                        .labels.value,
                                                    fontSize: "0.9rem",
                                                },
                                                total: {
                                                    ...state.earningChartData
                                                        .chartOptions
                                                        .plotOptions.pie.donut
                                                        .labels.total,
                                                    show: true,
                                                    label: "",
                                                    formatter: () => max,
                                                },
                                            },
                                        },
                                    },
                                },
                                responsive: [
                                    {
                                        breakpoint: 1800,
                                        options: {
                                            chart: {
                                                width: 40,
                                                height: 40,
                                            },
                                        },
                                    },
                                    {
                                        breakpoint: 1325,
                                        options: {
                                            chart: {
                                                width: 40,
                                                height: 40,
                                            },
                                        },
                                    },
                                    {
                                        breakpoint: 1200,
                                        options: {
                                            chart: {
                                                width: 40,
                                                height: 40,
                                            },
                                        },
                                    },
                                    {
                                        breakpoint: 1045,
                                        options: {
                                            chart: {
                                                width: 40,
                                                height: 40,
                                            },
                                        },
                                    },
                                    {
                                        breakpoint: 992,
                                        options: {
                                            chart: {
                                                width: 40,
                                                height: 40,
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                    ],
                };
            }
        },
        Update_User_Visitors_Stats(state, payload) {
            let max = 0;

            state.userConnects.visitors.map((item) => {
                max = item.dateTimes.length > max ? item.dateTimes.length : max;
            });

            state.userConnects.visitors = [];

            for (let item of payload.visitors) {
                state.userConnects = {
                    ...state.userConnects,
                    visitors: [
                        ...state.userConnects.visitors,
                        {
                            ...item,
                            ...state.earningChartData,

                            series: [max - item.dateTimes.length, max],
                            chartOptions: {
                                ...state.earningChartData.chartOptions,
                                chart: {
                                    ...state.earningChartData.chartOptions
                                        .chart,
                                    width: "50px",
                                },
                                colors: ["#B9B9C3", "#79AFEF"],
                                labels: ["عدد الزيارات", "عدد الزيارات الكلي"],
                                plotOptions: {
                                    ...state.earningChartData.chartOptions
                                        .plotOptions,
                                    pie: {
                                        ...state.earningChartData.chartOptions
                                            .plotOptions.pie,
                                        size: 60,
                                        donut: {
                                            ...state.earningChartData
                                                .chartOptions.plotOptions.pie
                                                .donut,
                                            labels: {
                                                ...state.earningChartData
                                                    .chartOptions.plotOptions
                                                    .pie.donut.labels,
                                                name: {
                                                    ...state.earningChartData
                                                        .chartOptions
                                                        .plotOptions.pie.donut
                                                        .labels.name,
                                                    fontSize: "0.9rem",
                                                },
                                                value: {
                                                    ...state.earningChartData
                                                        .chartOptions
                                                        .plotOptions.pie.donut
                                                        .labels.value,
                                                    fontSize: "0.9rem",
                                                },
                                                total: {
                                                    ...state.earningChartData
                                                        .chartOptions
                                                        .plotOptions.pie.donut
                                                        .labels.total,
                                                    show: true,
                                                    label: "",
                                                    formatter: () => max,
                                                },
                                            },
                                        },
                                    },
                                },
                                responsive: [
                                    {
                                        breakpoint: 1800,
                                        options: {
                                            chart: {
                                                width: 40,
                                                height: 40,
                                            },
                                        },
                                    },
                                    {
                                        breakpoint: 1325,
                                        options: {
                                            chart: {
                                                width: 40,
                                                height: 40,
                                            },
                                        },
                                    },
                                    {
                                        breakpoint: 1200,
                                        options: {
                                            chart: {
                                                width: 40,
                                                height: 40,
                                            },
                                        },
                                    },
                                    {
                                        breakpoint: 1045,
                                        options: {
                                            chart: {
                                                width: 40,
                                                height: 40,
                                            },
                                        },
                                    },
                                    {
                                        breakpoint: 992,
                                        options: {
                                            chart: {
                                                width: 40,
                                                height: 40,
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                    ],
                };
            }
        },
        Toggle_User_Followings_Comment(state, payload) {
            state.userConnects = {
                ...state.userConnects,
                followings: state.userConnects.followings.map((item) => {
                    return item.accountId == payload.accountId
                        ? {
                              ...item,
                              comments: item.comments.map((comment, index) => {
                                  return index == payload.commentIndex
                                      ? {
                                            ...comment,
                                            isToggled: !comment.isToggled,
                                        }
                                      : { ...comment };
                              }),
                          }
                        : { ...item };
                }),
            };
        },
    },
    actions: {
        getUsersList({ commit }) {
            api.get("AppUser/GetAllDash", ({ data }) => {
                commit("Get_User_List", data);
            });
        },
        getUserDetails({ commit }, id) {
            api.get(`AppUser/GetByIdDash?accountId=${id}`, ({ data }) => {
                commit("Set_User_Details", data);
            });
        },
        getUserConnects({ commit }, payload) {
            api.get(
                `AppUser/GetConnectsDash?accountId=${payload}`,
                ({ data }) => {
                    commit(`Set_User_Connects`, data);
                    commit("Update_User_Visitings_Stats", data);
                    commit("Update_User_Visitors_Stats", data);
                }
            );
        },
        getUserAbout({ commit }, payload) {
            api.get(
                `AppUser/GetOtherInfoDash?accountId=${payload}`,
                ({ data }) => {
                    commit(`Set_User_About`, data);
                }
            );
        },
        getUserJobDetails({ commit }, payload) {
            api.get(
                `AppUser/GetJobSubmitDash?jobId=${payload.jobId}&jobInfoId=${payload.jobInfoId}`,
                ({ data }) => {
                    commit(`Set_User_Job_Details`, data);
                }
            );
        },
        addUser({ commit }, paylaod) {
            api.post(
                `AppUser/AddDash`,
                paylaod,
                ({ data }) => {
                    commit("Add_User", data);
                },
                {
                    success: "تمت إضافة المستخدم بنجاح",
                    error: "فشل إضافة المستخدم ",
                }
            );
        },
        deleteRange({ commit }, payload) {
            api.delete(
                `AppUser/DeleteRangeDash`,
                () => {
                    commit("Delete_Users", payload);
                },
                {
                    confirm: "هل تريد بالتأكيد حذف المستخدمين المحددين؟",
                    success: "تم حذف المستخدمين بنجاح",
                    error: "فشل حذف المستخدمين ",
                },
                payload
            );
        },
        deleteUser({ commit }, payload) {
            api.delete(
                `AppUser/DeleteDash?id=${payload}`,

                () => {
                    commit("Delete_User");
                },
                {
                    confirm: "هل تريد بالتأكيد حذف المستخدم",
                    success: "تم حذف المستخدم بنجاح",
                    error: "فشل حذف المستخدم ",
                }
            );
        },
        updateUser(_, payload) {
            let formData = new FormData();

            formData.append("id", payload.id);
            formData.append("name", payload.name);
            formData.append("email", payload.email);
            formData.append("newPassword", payload.newPassword);
            formData.append("userName", payload.userName);
            formData.append("removeImage", payload.removeImage);
            formData.append("image", payload.image);

            api.post(
                "AppUser/ModifyDash",
                formData,
                ({ data }) => {
                    this.commit("Update_User", data);
                },
                {
                    confirm: "هل تريد بالتأكيد تعديل معلومات المستخدم؟",
                    success: "تم تعديل معلومات المستخدم بنجاح",
                    error: "فشل التعديل..يرجى إعادة المحاولة",
                }
            );
        },
    },
    getters: {
        userStats(state) {
            return {
                followings: state.userConnects.followings.length,
                visitings: state.userConnects.visitings.length,
                visitors: state.userConnects.visitors.length,
                followers: state.userConnects.followers.length,
            };
        },
        UserSubscriptionOrdersCount(state) {
            return state.subscriptions.length;
        },
        userNotificationOrdersCount(state) {
            return state.notifications.length;
        },
        userJobOrdersCount(state) {
            return state.jobs.length;
        },
    },
};
