import axiosIns from "@/libs/axios";
import api from "@api";

export default {
    state: {
        userReports: [],
        activityReports: [],
        userExcelDto: null,
        activityExcelDto: null,
        activeUserTab: 1,
        isUserReportUsersTabActive: true,
        isActivityReportUsersTabActive: false,
        reportSubStatuses: [
            {id: 1, name: "فعال", color: "success", number: 1 },
            {id: 2, name: "غير فعال", color: "warning", number: 2 },
        ],
    },
    mutations: {
        Get_All_User_Reports(state, payload) {  
            state.userReports = payload.map((item) => ({
                ...item,
                subStatuses:
                    new Date(item.endSub) >= Date.now() &&
                    new Date(item.startSub) <= Date.now()
                        ? 1
                        : 2,
            }));
            
        },
        Get_All_Activity_Reports(state, payload) {
            state.activityReports = payload.map((item) => ({
                ...item,
                subStatuses:
                    new Date(item.endSub) >= Date.now() &&
                    new Date(item.startSub) <= Date.now()
                        ? 1
                        : 2,
            }));
            
        },
        Get_User_Excel_Data(state, payload) {
            state.userExcelDto = payload;
        },
        Get_Activity_Excel_Data(state, payload) {
            state.activityExcelDto = payload;
        },
        Update_Report_Active_Tab(state, payload) {
            state.activeUserTab = payload
        }
    },
    actions: {
        getAppUserReports({ commit }) {
            api.get("ReportDash/GetAllAppUsersDash", ({ data }) => {
                commit("Get_All_User_Reports", data);
            });
        },
        getActivityReports({ commit }) {
            api.get("ReportDash/GetAllActivityDash", ({ data }) => {
                
                commit("Get_All_Activity_Reports", data);
            });
        },
        
        getUserExcelData() {
            axiosIns
            .get("ReportDash/ReportAppUser",{ responseType: 'blob' 
            })
            .then(({ data }) => {                

                let url = window.URL.createObjectURL(data);

                var a = document.createElement("a");
                document.body.appendChild(a);
                a.href = url;
                a.download = '2.xls';
                a.click();
                window.URL.revokeObjectURL(url);
                
            })
            .catch(() => {
                console.log("catch");
            });
            
        },
        getActivityExcelData() {
            axiosIns
            .get("ReportDash/Activity",{ responseType: 'blob' 
            })
            .then(({ data }) => {                

                let url = window.URL.createObjectURL(data);

                var a = document.createElement("a");
                document.body.appendChild(a);
                a.href = url;
                a.download = '2.xls';
                a.click();
                window.URL.revokeObjectURL(url);
                
            })
            .catch(() => {
                console.log("catch");
            });
        },
    },
};
