import api from "@api";
export default {
    state: {
        notifications: [],
        notificationDto: {
            title: "",
            body: "",
            image:"",
            filter: {
                accountType: "",
                senderId: "",
                cityId: null,
                isForVisitors: "",
                visitorTimes: "",
                isForFollowers: "",
            },
            receiversIds: [],
        },
        filterdActivities: [],
    },

    mutations: {
        Get_All_Notifications(state, payload) {
            state.notifications = payload;
        },
        Get_Filter_Admin(state, payload) {
            state.notifications = payload;
        },
        Get_Filter_Dto(state, payload) {
            state.filterdActivities = payload;
        },
        Reset_Filter_Results(state) {
            state.filterdActivities = [];
        },
        Delete_Notification(state, id) {
            state.notifications.splice(
                state.notifications.findIndex((item) => item.id == id),
                1
            );
        },
        Set_Notification_Dto(state, payload) {
            if (payload) Object.assign(state.notificationDto, payload);
            else {
                state.notificationDto = {
                    title: "",
                    body: "",
                    image:"",
                    accountType: "",
                    accountIds: [],
                    senderName: "",
                    filter: {
                        accountType: "",
                        senderId: "",
                        cityId: null,
                        isForVisitors: "",
                        visitorTimes: "",
                        isForFollowers: "",
                    },
                    receiversIds: [],
                };
            }
        },
        Add_Notification_Admin(state, payload) {
            state.notifications.unshift(payload);
        },
        Add_Notification(state, payload) {
            state.notifications.unshift(payload);
        },
        Set_Notification_Filter(state, payload) {
            state.notificationDto.filter = { ...payload };
        },
        Reset_Notification_Dto(state) {
            state.notificationDto = {
                title: "",
                body: "",
                image:"",
                filter: {
                    accountType: "",
                    senderId: "",
                    cityId: null,
                    isForVisitors: "",
                    visitorTimes: "",
                    isForFollowers: "",
                },
                receiversIds: [],
            }
        }
    },
    actions: {
        getNotificationsList({ commit }) {
            api.get("Notification/GetAllDash", ({ data }) => {
                commit("Get_All_Notifications", data);
                this.commit("Set_Notification_Dto");
            });
        },
        getNotificationDetails({ commit }, id) {
            api.get(
                `Notification/GetByIdDash=${id}`,
                ({ data }) => {
                    commit("Set_Notification_Dto", data);
                },
                false
            );
        },
        getFilterByAdmin({ commit }, payload) {
            api.post(
                "Notification/GetFilterForAdminNotification",
                payload,
                ({ data }) => {
                    commit("Get_Filter_Dto", data);
                },
                false,
                { noLoading: true }
            );
        },
        getFilterByActivity({ commit }, payload) {
            api.post(
                "Notification/GetFilterNotificationDto",
                payload,
                ({ data }) => {
                    commit("Get_Filter_Dto", data);
                },
                false,
                { noLoading: true }
            );
        },
        addNotificationAdmin({ commit }, payload) {
            api.form.post(
                "Notification/AddDashByAdmin",
                payload,
                ({ data }) => {
                    commit("Add_Notification_Admin", data);
                    commit("Reset_Notification_Dto")
                },
                {
                    success: "تم إضافة الإشعار بنجاح",
                    error: "فشل إضافة الإشعار",
                }
            );
        },
        addNotification({ commit }, payload) {
            api.form.post(
                "Notification/AddDash",
                payload,
                ({ data }) => {
                    commit("Add_Notification", data);
                    commit("Reset_Notification_Dto")
                },
                {
                    success: "تم إضافة الإشعار بنجاح",
                    error: "فشل إضافة الإشعار",
                }
            );
        },
        deleteNotification({ commit }, id) {
            api.delete(
                `Notification/Delete?id=${id}`,
                () => {
                    commit("Delete_Notification", id);
                },
                {
                    confirm: " هل تريد بالتأكيد حذف الإشعار؟",
                    success: "تم حذف الإشعار بنجاح",
                    error: "فشل حذف الإشعار ..يرجى اعادة المحاولة ",
                }
            );
        },
    },
};
