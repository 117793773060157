import { Admin, SuperAdmin } from "@/router";
export default [
    {
        path: "",
        redirect: "admin/home",
    },
    {
        path: "admin/home",
        name: "home",
        component: () => import("@admin/home/Home.vue"),
        meta: () => ({
            pageTitle: "الرئيسية",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "الرئيسية",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "admin/users",
        name: "users",
        components: {
            default: () => import("@admin/users/Users.vue"),
            actions: () => import("@admin/users/components/AddUser.vue"),
        },
        meta: () => ({
            pageTitle: "الحسابات ",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "المستخدمين",
                    active: false,
                    icon:'users-alt'
                },
            ],
        }),
    },
    {
        path: "admin/users/details/:id",
        name: "userDetails",
        component: () => import("@admin/users/pages/UserDetails.vue"),
        props: (route) => {
            return {
                id: route.params.id,
            };
        },
        meta: ({ query }) => ({
            pageTitle: "الحسابات ",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "الحسابات ",
                    active:false,
                    icon:'users-alt',
                    to: "/admin/users"
                },
                {
                    text: "المستخدمين",
                    to: "/admin/users",
                },
                {
                    text: query ? query.username : "تفاصيل المستخدم",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "admin/accounts",
        name: "Accounts",
        components: {
            default: () => import("@admin/accounts/Accounts.vue"),
            actions: () => import("@admin/accounts/components/AddAccount.vue"),
        },
        meta: () => ({
            pageTitle: "حسابات المستخدمين",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "لوحة التحكم",
                    active: false,
                    icon:'user-circle'
                },
            ],
        }),
    },
    {
        path: "admin/accounts/:id",
        name: "AdminAccountDetails",
        props: true,
        components: {
            default: () => import("@admin/accounts/pages/AccountDetails.vue"),
        },
        meta: () => ({
            pageTitle: "حسابات المستخدمين",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "لوحة التحكم",
                    active: false,
                    icon:"user-circle",
                    to: "/admin/accounts"
                },
                {
                    text: "تفاصيل لوحة التحكم ",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/adsAndOffers",
        name: "adsAndOffers",
        components: {
            default: () => import("@admin/adsAndOffers/AdsAndOffers.vue"),
            actions: () =>
                import(
                    "@admin/adsAndOffers/components/ads-and-offers-action-button.vue"
                ),
        },
        meta: () => ({
            pageTitle: "إدارة المحتوى",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "الإعلانات و العروض",
                    active: false,
                    icon: "tv-retro",
                },
            ],
        }),
    },
    {
        path: "/admin/adsAndOffers/:id",
        name: "adsAndOffersDetails",
        components: {
            default: () =>
                import("@admin/adsAndOffers/pages/AdsAndOffers-details.vue"),
        },
        props: {
            default: true,
        },
        meta: () => ({
            pageTitle: "إدارة المحتوى",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "الإعلانات و العروض",
                    to: "/admin/adsAndOffers/",
                    icon: "tv-retro",
                },
                {
                    text: "تفاصيل الإعلان",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "admin/notifications",
        name: "notifications",
        components: {
            default: () => import("@admin/notifications/Notifications.vue"),
            actions: () =>
                import("@admin/notifications/components/AddNotification.vue"),
        },
        meta: () => ({
            pageTitle: "إدارة المحتوى",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "الإشعارات",
                    active: false,
                    icon: "bell",
                },
            ],
        }),
    },
    {
        path: "admin/notifications/:id",
        name: "notificationDetails",
        components: {
            default: () =>
                import("@admin/notifications/pages/Notifications-details.vue"),
        },
        props: {
            default: true,
        },
        meta: () => ({
            pageTitle: "إدارة المحتوى",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "الطلبات",
                    active: false,
                    icon: "envelope-download",
                    to:'/admin/orders'
                },
                {
                    text: "طلب إشعار",
                    active: true,
                },
                {
                    text: "تفاصيل إشعار",
                    active: false,
                    to:"/admin/notifications"
                },
            ],
        }),
    },
    {
        path: "admin/jobs",
        name: "Jobs",
        components: {
            default: () => import("@admin/jobs/Jobs.vue"),
            actions: () => import("@admin/jobs/components/JobActions.vue"),
        },
        meta: () => ({
            pageTitle: "ادارة المحتوى",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "الوظائف",
                    active: false,
                    icon: "bag",
                },
            ],
        }),
    },
    {
        path: "admin/reports",
        name: "Reports",
        components: {
            default: () => import("@admin/reports/Reports.vue"),
            actions: () =>
                import("@admin/reports/components/ReportActions.vue"),
        },
        meta: () => ({
            pageTitle: "ادارة المحتوى",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "التقارير",
                    active: false,
                    icon: "bag",
                },
            ],
        }),
    },
    {
        path: "admin/reports/printUserData",
        name: "PrintUserReports",
        components: {
            default: () =>
                import("@admin/reports/components/prints/PrintUserReports.vue"),
        },
        meta: () => ({
            pageTitle: "ادارة المحتوى",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "التقارير",
                    active: false,
                    icon: "file-alt",
                    to:'/admin/reports'
                },
                {
                    text: "طباعة",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "admin/reports/printActivityData",
        name: "PrintActivityReports",
        components: {
            default: () =>
                import(
                    "@admin/reports/components/prints/PrintActivityReports.vue"
                ),
        },
        meta: () => ({
            pageTitle: "ادارة المحتوى",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "التقارير",
                    active: false,
                    icon: "file-alt",
                    to:'/admin/reports'
                },
                {
                    text: "طباعة",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "admin/jobs/:id",
        name: "JobsDetails",
        component: () => import("@admin/jobs/pages/JobDetails.vue"),
        props: true,
        meta: () => ({
            pageTitle: "ادارة المحتوى",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "الوظائف",
                    active: false,
                    icon: "bag",
                    to:'/admin/jobs'
                },
                {
                    text: "تفاصيل الوظائف",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "admin/orders",
        name: "Orders",
        components: {
            default: () => import("@admin/orders/Orders.vue"),
            actions: () =>
                import("@admin/orders/components/OrderActionButton.vue"),
        },
        meta: () => ({
            pageTitle: "ادارة المحتوى",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "الطلبات",
                    active: false,
                    icon: "envelope-download",
                },
            ],
        }),
    },
    {
        path: "admin/orders/notificationRequest/:id",
        name: "orderNotificationRequest",
        props: {
            default: true,
        },
        components: {
            default: () =>
                import("@admin/orders/pages/NotificationOrderDetails.vue"),
        },
        meta: () => ({
            pageTitle: "ادارة المحتوى",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "الطلبات",
                    active: false,
                    icon: "envelope-download",
                    to:'/admin/orders'
                },
                {
                    text: "تفاصيل الإشعار",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "admin/orders/adsRequest/:id",
        name: "orderAdsRequest",
        props: {
            default: true,
        },
        components: {
            default: () => import("@admin/orders/pages/AdsOrderDetails.vue"),
        },
        meta: () => ({
            pageTitle: "ادارة المحتوى",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "الطلبات",
                    active: false,
                    icon: "envelope-download",
                    to:'/admin/orders'
                },
                {
                    text: "تفاصيل الإعلان",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "admin/orders/jobRequest/:id",
        name: "orderJobRequest",
        props: {
            default: true,
        },
        components: {
            default: () => import("@admin/orders/pages/JobOrderDetails.vue"),
        },
        meta: () => ({
            pageTitle: "ادارة المحتوى",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "الطلبات",
                    active: false,
                    icon: "envelope-download",
                    to:'/admin/orders'
                },
                {
                    text: "تفاصيل الوظيفة",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "admin/subscriptions",
        name: "Subscriptions",
        components: {
            default: () => import("@admin/subscriptions/Subscriptions.vue"),
            actions: () =>
                import("@admin/subscriptions/components/AddSubscrip.vue"),
        },
        meta: () => ({
            pageTitle: "إدارة المحتوى",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "الإشتراكات",
                    active: false,
                    icon: "receipt",
                },
            ],
        }),
    },

    {
        path: "admin/activities",
        name: "Activities",
        components: {
            default: () => import("@admin/activities/Activities.vue"),
            actions: () =>
                import("@admin/activities/components/ActivityActions.vue"),
        },
        meta: () => ({
            pageTitle: "ادارة المحتوى",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "الفعاليات",
                    active: false,
                    icon: "store",
                },
            ],
        }),
    },
    {
        path: "admin/settings",
        name: "Settings",
        components: {
            default: () => import("@admin/settings/Settings.vue"),
            actions: () =>
                import("@admin/settings/components/QuestionActionButton.vue"),
        },
        meta: () => ({
            pageTitle: "ادارة المحتوى",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "الإعدادات",
                    active: false,
                    icon: "setting",
                },
            ],
        }),
    },
    {
        path: "admin/settings/questions/addQuestion",
        name: "add-question",
        components: {
            default: () => import("@admin/settings/pages/AddQuestion.vue"),
        },
        meta: () => ({
            pageTitle: "ادارة المحتوى",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "الإعدادات",
                    active: true,
                    icon: "setting",
                    to:'/admin/settings'
                },
                {
                    text: "إضافة سؤال",
                    active: true,
                    icon: "setting",
                },
            ],
        }),
    },
    {
        path: "admin/settings/questions/:id",
        name: "question-details",
        components: {
            default: () => import("@admin/settings/pages/QuestionDetails.vue"),
        },
        props: {
            default: true,
        },
        meta: () => ({
            pageTitle: "ادارة المحتوى",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "الإعدادات",
                    active: true,
                    icon: "setting",
                    to:'/admin/settings'
                },
                {
                    text: "تفاصيل السؤال",
                    active: true,
                    icon: "setting",
                },
            ],
        }),
    },
    {
        path: "admin/activities/:id",
        name: "ActivitiesDetails",
        components: {
            default: () =>
                import("@admin/activities/pages/ActivityDetails.vue"),
        },
        props: {
            default: true,
        },
        meta: ({ query }) => ({
            pageTitle: query ? query.username : "تفاصيل الفعالية",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "الفعاليات",
                    active: false,
                    to:'/admin/activities',
                    icon: "store",
                },
                {
                    text: query ? query.username : "تفاصيل الفعالية",
                    to: "/admin/activities",
                    active: true,
                },
            ],
        }),
    },

    {
        path: "admin/activities/:activityId/subscriptions/:id",
        name: "ActivitiySubscriptionOrderDetails",
        components: {
            default: () =>
                import("@admin/activities/pages/ActivityDetails.vue"),
        },
        props: {
            default: true,
        },
        meta: () => ({
            pageTitle: "الفعاليات",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "الفعاليات",
                    active: true,
                    to:'/admin/activities'
                },
                {
                    text: "تفاصيل الفعالية",
                    active: false,
                    icon: "store",
                },
                {
                    text: "تفاصيل الاشتراك ",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "admin/activities/:activityId/notifications/:id",
        name: "ActivitiyNotificationOrderDetails",
        components: {
            default: () =>
                import("@admin/orders/pages/NotificationOrderDetails.vue"),
        },
        props: {
            default: true,
        },
        meta: () => ({
            pageTitle: "الفعاليات",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "الفعاليات",
                    active: false,
                    to:'/admin/activities',
                    icon:'store'
                },
                {
                    text: "تفاصيل الإشعار",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "admin/activities/:activityId/ads/:id",
        name: "ActivitiyAdsOrderDetails",
        components: {
            default: () => import("@admin/orders/pages/AdsOrderDetails.vue"),
        },
        props: {
            default: true,
        },
        meta: () => ({
            pageTitle: "الفعاليات",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "تفاصيل الفعالية",
                    active: false,
                    icon: "store",
                    to:'/admin/activities'
                },
                {
                    text: "تفاصيل الإعلان",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "admin/activities/:activityId/jobs/:id",
        name: "ActivitiyJobOrderDetails",
        components: {
            default: () => import("@admin/orders/pages/JobOrderDetails.vue"),
        },
        props: {
            default: true,
        },
        meta: () => ({
            pageTitle: "الفعاليات",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "تفاصيل الفعالية",
                    active: false,
                    icon: "store",
                    to:'/admin/activities'
                },
                {
                    text: "تفاصيل الوظيفة",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/users/:id/jobs/:jobId/jobInfoId/:jobInfoId",
        name: "UserJobOrderDetails",
        components: {
            default: () => import("@admin/users/pages/UserJobOrderForm.vue"),
            actions: () =>
                import(
                    "@admin/users/components/userJobDetails/UserJobOrderActionButton.vue"
                ),
        },
        props: {
            default: true,
        },
        meta: () => ({
            pageTitle: "الحسابات",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "المستخدمين",
                    active: false,
                    to:'/admin/users',
                    icon: "user",
                },
                {
                    text: "تفاصيل المستخدم",
                    active: false,
                },
                {
                    text: "تفاصيل الوظيفة",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "admin/users/:userId/notifications/:id",
        name: "UserNotificationOrderDetails",
        components: {
            default: () =>
                import("@admin/users/pages/UserNotificationForm.vue"),
            actions: () =>
                import(
                    "@admin/users/components/userNotificationDetails/UserNotificationOrderActionButton.vue"
                ),
        },
        props: {
            default: true,
        },
        meta: () => ({
            pageTitle: "الحسابات",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "المستخدمين",
                    active: false,
                    icon: "user",
                    to:'/admin/users',
                },
                {
                    text: "تفاصيل المستخدم",
                    active: false,
                },
                {
                    text: "تفاصيل الوظيفة",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/contactUs",
        name: "contactUs",
        component: () => import("@admin/contactUs/ContactUs.vue"),
        meta: () => ({
            pageTitle: "تواصل معنا ",
            roles: [Admin, SuperAdmin],
            layout: "vertical",

            breadcrumb: [
                {
                    icon: "envelope",
                    text: " بريد الشركة ",
                    active: false,
                },
            ],
        }),
    },
    {
        path: "/admin/contactUs/:id",
        name: "contactUsDetails",
        props: true,
        component: () => import("@admin/contactUs/pages/ContactUsDetails.vue"),
        meta: () => ({
            pageTitle: " تواصل معنا",
            roles: [Admin, SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "envelope",
                    text: " بريد الشركة ",
                    to: "/admin/contactUs",
                    active: false,
                },
                {
                    text: "  تفاصيل البريد",
                    active: true,
                },
            ],
        }),
    },
];
