import api from "@api";
import { nullGuid } from "@Ekcore/util/global.js";
import router from "@/router";

export default {
    state: {
        ads: [],
        adsDto: {
            senderId: null,
            startDate: "",
            endDate: "",
            file: "",
            content: "",
            name: "",
            dashResponse: null,
            commercialName: "",
            cityName: "",
            adsDatesList: [],
        },
        adsStatus: [
            { name: "فعال", color: "success", number: 3 },
            { name: "منتهي", color: "warning", number: 4 },
        ],
    },
    getters: {
        adsStatusName(state) {
            let statusName = "";
            switch (state.adsDto.dashResponse) {
                case 1:
                    statusName = "قيد الانتظار";
                    break;
                case 2:
                    statusName = "مرفوض";
                    break;
                case 3:
                    statusName = "مقبول";
                    break;
                case 4:
                    statusName = "منتهي";
                    break;
            }
            return statusName;
        },
        adsIsActive(state) {
            return state.adsDto.dashResponse == 3;
        },
        adsIsExpired(state) {
            return state.adsDto.dashResponse == 4;
        },
    },
    mutations: {
        Get_Ads_List(state, payload) {
            state.ads = [...payload];
            state.adsDto = {
                id: nullGuid,
                senderId: null,
                startDate: "",
                endDate: "",
                file: "",
                content: "",
                name: "",
                dashResponse: null,
                commercialName: "",
                cityName: "",
                adsDatesList: [],
            };
        },
        Update_Ads_List(state, payload) {
            state.ads = state.ads.map((el) => {
                if (el.id == payload.id) return payload;
                return el;
            });
        },
        Update_Ads(state, payload) {
            state.adsDto = {
                ...payload,
                adsDatesList: [
                    new Date(payload.startDate),
                    new Date(payload.endDate),
                ],
            };
        },
        Update_Ads_Dto_Date(state, payload) {
            state.adsDto = {
                ...state.adsDto,
                startDate: payload.split("to")[0],
                endDate: payload.split("to")[1],
            };
        },
        Update_Ads_Dto_Activity_Details(state, payload) {
            state.adsDto = {
                ...state.adsDto,
                comarcialActivityName: payload.commercialName,
                cityName: payload.location,
            };
        },
        Reset_Ads_Dto(state) {
            Object.assign(state.adsDto, {
                id: nullGuid,
                senderId: null,
                startDate: "",
                endDate: "",
                file: "",
                content: "",
                name: "",
                dashResponse: null,
                commercialName: "",
                cityName: "",
                adsDatesList: [],
            });
        },
        Add_Ads(state, payload) {
            state.ads.unshift(payload);
        },
        Delete_Ads(state, id) {
            state.ads.splice(
                state.ads.findIndex((item) => item.id == id),
                1
            );
        },
        Delete_Ads_List(state, ids) {
            state.ads = state.ads.filter(
                (service) => !ids.includes(service.id)
            );
        },
    },
    actions: {
        getAdsList({ commit }) {
            api.get("Service/GetAllDash?language=" + "Ar", ({ data }) => {
                commit("Get_Ads_List", data);
            });
        },
        fetchAds({ commit }, payload) {
            api.get(`Service/GetByIdDash?id=${payload}`, ({ data }) => {
                commit("Update_Ads", data);
            });
        },
        addAds({ commit }, payload) {
            console.log("payload", payload);
            api.post(
                "Service/AddDash",
                payload,
                ({ data }) => {
                    commit("Add_Ads", data);
                    commit("Reset_Ads_Dto");
                },
                {
                    success: "تم إضافة الإعلان بنجاح",
                    error: "فشل إضافة الإعلان",
                }
            );
        },
        updateAds({ commit }, payload) {
            api.post(
                "Service/ModifyDash",
                payload,
                ({ data }) => {
                    commit("Update_Ads", data);
                    router.push("/admin/adsAndOffers");
                },
                {
                    success: "تم تعديل الإعلان بنجاح",
                    error: "فشل تعديل الإعلان",
                }
            );
        },
        deleteAds(_, payload) {
            api.delete(
                `Service/Delete?id=${payload}`,
                ({ data }) => {
                    if (data) {
                        router.push("/admin/adsAndOffers");
                    }
                },
                {
                    confirm: "هل تريد حذف الإعلان؟",
                    success: "تم حذف الإعلان بنجاح",
                    error: "فشل حذف الإعلان",
                }
            );
        },
        deleteRangeAds({ commit }, payload) {
            api.delete(
                "Service/DeleteRange",
                ({ data }) => {
                    if (data) {
                        commit("Delete_Ads_List", payload);
                    }
                },
                {
                    confirm: "هل تريد حذف الإعلان؟",
                    success: "تم حذف الإعلان بنجاح",
                    error: "فشل حذف الإعلان",
                },
                payload
            );
        },
    },
};
