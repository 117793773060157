import api from "@api";
import { getSearchResultOnRow } from "@Ekcore/util";

export default {
    state: {
        activeTab: 0,
        subscripFilters: {
            subStatus: "",
            fromDate: "",
            toDate: "",
        },
        subUsersList: [],
        subActivitiesList: [],
        subAppUserName: [],
        subActivityName: [],
        filterdFeatures: [],
        subscripDto: {
            accountId: "",
            startSub: "",
            endSub: "",
            features: [],
        },
        subDetailsDto: {
            accountId: "",
            accountName: "",
            accountSubId: "",
            endSub: "",
            features: "",
            originalIndex: "",
            startSub: "",
            subNumber: "",
            subStatuses: "",
            userName: "",
        },
        subStatus: [
            { name: "فعال", color: "success", number: 1 },
            { name: "غير فعال", color: "warning", number: 2 },
        ],
    },

    mutations: {
        Set_Subscripe_Filter(state, payload) {
            state.subscripFilters = { ...payload };
        },
        Get_Details_Dto(state, payload) {
            state.subDetailsDto = { ...payload };
        },
        Get_All_APP_User(state, payload) {
            state.subUsersList = payload.map((item) => {
                return {
                    ...item,
                    subStatuses:
                        new Date(item.endSub) >= Date.now() &&
                        new Date(item.startSub) <= Date.now()
                            ? 1
                            : 2,
                };
            });
            console.log("state.subUsersList", state.subUsersList)
        },
        Set_Active_Tab(state, payload) {
            state.activeTab = payload;
        },
        Get_All_Activity(state, payload) {
            state.subActivitiesList = payload.map((item) => {
                return {
                    ...item,
                    subStatuses:
                        new Date(item.endSub) >= Date.now() &&
                        new Date(item.startSub) <= Date.now()
                            ? 1
                            : 2,
                };
            });
            console.log("state.subUsersList", state.subUsersList)
        },
        Get_Name_User(state, payload) {
            state.subAppUserName = [...payload];
        },
        Get_Activity_Name(state, payload) {
            state.subActivityName = [...payload];
        },
        Get_Features(state, payload) {
            state.filterdFeatures = [...payload].map((el) => ({
                name: el,
                id: el,
            }));
            this.commit("Set_Dto_Ftrs", payload);
        },
        Set_Dto_Ftrs(state, payload) {
            state.subDetailsDto.features = state.subDetailsDto.features.filter(
                (el) => payload.includes(el)
            );
        },
        Add_APP_User(state, payload) {
            state.subUsersList.unshift({
                ...payload,
                subStatuses:
                    new Date(payload.endSub) >= Date.now() &&
                    new Date(payload.startSub) <= Date.now()
                        ? 1
                        : 2,
            });
        },
        Add_Activity(state, payload) {
            state.subActivitiesList.unshift({
                ...payload,
                subStatuses:
                    new Date(payload.endSub) >= Date.now() &&
                    new Date(payload.startSub) <= Date.now()
                        ? 1
                        : 2,
            });
        },
        Delete_Sub(state, id) {
            if (state.activeTab === 0) {
                state.subUsersList = state.subUsersList.filter(
                    (el) => el.id != id
                );
            } else {
                state.subActivitiesList = state.subActivitiesList.filter(
                    (el) => el.id != id
                );
            }
        },
        Delete_Subs(state, ids) {
            if (state.activeTab === 0) {
                state.subUsersList = state.subUsersList.filter(
                    (el) => !ids.includes(el.id)
                );
            } else {
                state.subActivitiesList = state.subActivitiesList.filter(
                    (el) => !ids.includes(el.id)
                );
            }
        },
        Reset_Subscrip_Dto(state) {
            Object.assign(state.subscripDto, {
                accountId: "",
                startSub: "",
                endSub: "",
                features: [],
            });
        },
        Reset_Filter(state) {
            Object.assign(state.subDetailsDto, {
                features: "",
            });
        },
        Update_Sub_Dto_Date(state, payload) {
            if (payload)
                state.subscripDto = {
                    ...state.subscripDto,
                    startSub: payload.split("to")[0].trim(),
                    endSub: payload.split("to")[1].trim(),
                };
        },
        Update_User(state, payload) {
            let idx = state.subUsersList.findIndex((el) => {
                return el.accountSubId === payload.accountSubId;
            });
            state.subUsersList[idx] = {
                ...payload,
                subStatuses:
                    new Date(payload.endSub) >= Date.now() &&
                    new Date(payload.startSub) <= Date.now()
                        ? 1
                        : 2,
            };
            state.subDetailsDto = {...payload, 
                subStatuses:
                    new Date(payload.endSub) >= Date.now() &&
                    new Date(payload.startSub) <= Date.now()
                        ? 1
                        : 2,
            }
            
            state.subUsersList = [...state.subUsersList];
        },
        Update_Activity(state, payload) {
            let idx = state.subActivitiesList.findIndex((el) => {
                return el.accountSubId === payload.accountSubId;
            });

            state.subDetailsDto = {...payload, 
                subStatuses:
                    new Date(payload.endSub) >= Date.now() &&
                    new Date(payload.startSub) <= Date.now()
                        ? 1
                        : 2,
            }
            
            state.subActivitiesList[idx] = {
                ...payload,
                subStatuses:
                    new Date(payload.endSub) >= Date.now() &&
                    new Date(payload.startSub) <= Date.now()
                        ? 1
                        : 2,
            };
            state.subActivitiesList = [...state.subActivitiesList];
        },
    },

    actions: {
        getAllAppUser({ commit }) {
            api.get("Subscription/GetAllAppUserDash", ({ data }) => {
                commit("Get_All_APP_User", data);
            });
        },
        getAllActivity({ commit }) {
            api.get("Subscription/GetAllActivityDash", ({ data }) => {
                commit("Get_All_Activity", data);
            });
        },
        getAppUserName({ commit }) {
            api.get("Subscription/GetAppUserNamesDash", ({ data }) => {
                commit("Get_Name_User", data);
            });
        },
        getActivityName({ commit }) {
            api.get("Subscription/GetActivityNamesDash", ({ data }) => {
                commit("Get_Activity_Name", data);
            });
        },
        getFeatures({ commit }, payload) {
            api.get(
                `Subscription/GetFeaturesDash?AccountId=${payload.accountId}&FeaturesType=${payload.featuresType}&StartSub=${payload.startSub}&EndSub=${payload.endSub}`,
                ({ data }) => {
                    commit("Get_Features", data);
                },
                { noLoading: true }
            );
        },
        addAppUserDash({ commit }, payload) {
            api.post(
                "Subscription/AddAppUserDash",
                payload,
                ({ data }) => {
                    commit("Add_APP_User", data);
                    commit("Reset_Subscrip_Dto")
                },
                {
                    success: "تمت إضافة الإشتراك بنجاح",
                    error: "فشلت الإضافة .. يرجى إعادة المحاولة",
                }
            );
        },
        addActivityDash({ commit }, payload) {
            api.post(
                "Subscription/AddActivityDash",
                payload,
                ({ data }) => {
                    commit("Add_Activity", data);
                    commit("Reset_Subscrip_Dto")
                },
                {
                    success: "تمت إضافة الإشتراك بنجاح",
                    error: "فشلت الإضافة .. يرجى إعادة المحاولة",
                }
            );
        },
        deleteSub({ commit }, id) {
            api.delete(
                `Subscription/DeleteDash?id=${id}`,
                () => {
                    commit("Delete_Sub", id);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف الاشتراك",
                    success: "تم حذف الاشتراك بنجاح",
                    error: "فشل حذف الاشتراك ..يرجى اعادة المحاولة ",
                }
            );
        },
        deleteSubs({ commit }, ids) {

            console.log("ids", ids)
            api.delete(
                "Subscription/DeleteRangeDash",
                () => {
                    commit("Delete_Subs", ids);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف الاشتراكات المحددة",
                    success: "تم حذف الاشتراكات بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                ids
            );
        },
        updateUserSubscripe({ commit }, payload) {
            api.post(
                "Subscription/ModifyAppUserDash",
                payload,
                ({ data }) => {
                    commit("Update_User", data);
                },
                {
                    success: "تم تعديل الاشتراك بنجاح",
                    error: "فشل تعديل الاشتراك",
                }
            );
        },
        updateActivitySubscripe({ commit }, payload) {
            console.log("payload", payload)
            api.post(
                "Subscription/ModifyActivityDash",
                payload,
                (res) => {
                    commit("Update_Activity", res.data);
                },
                {
                    success: "تم تعديل الاشتراك بنجاح",
                    error: "فشل تعديل الاشتراك",
                }
            );
        },
    },
    getters: {
        activeSubscriptionsList(
            { subscripFilters, activeTab, subUsersList, subActivitiesList },
            getters,
            { filter }
        ) {

            function setDateTime(date) {
                let ndate = new Date(date)
                ndate.setHours(12)
                ndate.setMinutes(0)
                ndate.setSeconds(0)
                ndate.setMilliseconds(0)
                return ndate
            }
            
            return (activeTab === 0 ? subUsersList : subActivitiesList).filter(
                (el) => {
                    return (
                        getSearchResultOnRow(filter, el) &&
                        (el.subStatuses == subscripFilters.subStatus ||
                            !subscripFilters.subStatus) &&
                        ((setDateTime(new Date(el.endSub)) >=  setDateTime(new Date(subscripFilters.fromDate)) &&
                            setDateTime(new Date(el.endSub)) <= setDateTime(new Date(subscripFilters.toDate))) ||
                            !subscripFilters.fromDate ||
                            !subscripFilters.toDate)
                    );
                }
            );
        },
    },
};
