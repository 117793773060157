import Vue from "vue";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import Unicon from "vue-unicons";
import EKDialog from "@Ekcore/components/EK-dialog";
import EKModal from "@Ekcore/components/EK-modal";
import activaitor from "@Ekcore/components/EK-dialog/activaitor";
import EKPagination from "@Ekcore/components/EK-pagination";
import EKTable from "@Ekcore/components/EK-table";
import EKTableCollapse from "@Ekcore/components/EK-table-collapse";
import EKTree from "@Ekcore/components/EK-tree/tree.js";
import EKInputImage from "@Ekcore/components/EK-forms/EK-input-image";
import EKInputText from "@Ekcore/components/EK-forms/EK-input-text";
import EKInputSelect from "@Ekcore/components/EK-forms/EK-input-select";
import EKInputPicker from "@Ekcore/components/EK-forms/EK-input-picker";
import EKDatePicker from "@Ekcore/components/EK-forms/EK-date-picker";
import EKInputTextarea from "@Ekcore/components/EK-forms/EK-input-textarea";
import EKImagePicker from "@Ekcore/components/EK-forms/EK-image-picker";
import EkProfileImage from "@Ekcore/components/EK-forms/EK-image-picker";
import EKInputPhone from "@Ekcore/components/EK-forms/EK-input-phone";
import EKInputRange from "@Ekcore/components/EK-forms/EK-input-range";
import aVDatatable from "a-v-datatable";
import "a-v-datatable/dist/a-v-datatable.css";

import { ValidationObserver, ValidationProvider } from "vee-validate";
Vue.use(Unicon);
Vue.component(FeatherIcon.name, FeatherIcon);
Vue.component("ek-dialog", EKDialog);
Vue.component("ek-activator", activaitor);
Vue.component("ek-pagination", EKPagination);
Vue.component("ek-table", EKTable);
Vue.component("ek-table-collapse", EKTableCollapse);
Vue.component("ek-tree", EKTree);
Vue.component("ek-date-picker", EKDatePicker);
Vue.component("ek-input-image", EKInputImage);
Vue.component("ek-input-text", EKInputText);
Vue.component("ek-input-select", EKInputSelect);
Vue.component("ek-input-picker", EKInputPicker);
Vue.component("ek-input-textarea", EKInputTextarea);
Vue.component("ek-input-phone", EKInputPhone);
Vue.component("ek-input-range", EKInputRange);
Vue.component("ek-image-picker", EKImagePicker);
Vue.component("ek-modal", EKModal);
Vue.component("ek-date-picker", EKDatePicker);
Vue.component("ek-profile-image", EkProfileImage);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.use(aVDatatable);
import {
    uniMessage,
    uniBuilding,
    uniTrash,
    uniMoon,
    uniBell,
    uniCircle,
    uniSun,
    uniPlus,
    uniHomeAlt,
    uniTrashAlt,
    uniCalendarAlt,
    uniUser,
    uniDesktop,
    uniHorizontalAlignRight,
    uniMap,
    uniNewspaper,
    uniServerAlt,
    uniLayerGroup,
    uniSearch,
    uniTimes,
    uniFileBlank,
    uniFolderOpen,
    uniFolder,
    uniAngleRight,
    uniAngleLeft,
    uniAngleUp,
    uniEllipsisV,
    uniSync,
    uniAngleDown,
    uniPen,
    uniArrowLeft,
    uniArrowDown,
    uniFavorite,
    uniShoppingBag,
    uniSwatchbook,
    uniImageRedo,
    uniTvRetro,
    uniBag,
    uniReceipt,
    uniBriefcase,
    uniStore,
    uniEnvelopeDownload,
    uniUsersAlt,
    uniUserCircle,
    uniSetting,
    uniFileAlt,
    uniCalender,
    uniTwitterAlt,
    uniInstagram,
    uniEnvelope,
    uniFacebookF,
    uniMobileAndroid,
    uniLinkedinAlt,
    uniYoutube,
    uniEdit,
    uniCommentAltDots,
    uniUserCheck,
    uniFileQuestion,
    uniBan,
    uniAngleLeftB,
    uniTelegramAlt,
    uniCommentAltMessage,
    uniEye,
    uniWhatsapp,
    uniGithubAlt,
    uniGraduationCap,
    uniPhone,
    uniMailbox,
    uniUniversity,
    uniFile,
    uniForwadedCall,
    uniChatBubbleUser,
    uniGlobe,
    uniBolt,
    uniGithub,
    uniTwitter,
    uniTelegram,
    uniLinkedin,
    uniLink,
    uniCheck
} from "vue-unicons/src/icons";

Unicon.add([
    // for theme
    uniCheck,
    uniCommentAltMessage,
    uniLinkedinAlt,
    uniMessage,
    uniYoutube,
    uniFacebookF,
    uniMobileAndroid,
    uniEnvelope,
    uniTwitterAlt,
    uniInstagram,
    uniCalender,
    uniStore,
    uniBag,
    uniImageRedo,
    uniBuilding,
    uniTrash,
    uniPlus,
    uniLayerGroup,
    uniServerAlt,
    uniDesktop,
    uniNewspaper,
    uniMap,
    uniSun,
    uniHorizontalAlignRight,
    uniMoon,
    uniBell,
    uniCircle,
    uniHomeAlt,
    uniFileBlank,
    uniCalendarAlt,
    uniSearch,
    uniUser,
    uniTrashAlt,
    uniTimes,
    uniFolderOpen,
    uniFolder,
    uniAngleRight,
    uniAngleUp,
    uniAngleLeft,
    uniAngleDown,
    uniEllipsisV,
    uniSync,
    uniArrowLeft,
    uniArrowDown,
    uniPen,
    uniFavorite,
    uniShoppingBag,
    uniSwatchbook,
    uniTvRetro,
    uniReceipt,
    uniBriefcase,
    uniEnvelopeDownload,
    uniUsersAlt,
    uniUserCircle,
    uniSetting,
    uniFileAlt,
    uniCommentAltDots,
    uniEdit,
    uniUserCheck,
    uniFileQuestion,
    uniBan,
    uniAngleLeftB,
    uniTelegramAlt,
    uniEye,
    uniWhatsapp,
    uniGithubAlt,
    uniLinkedinAlt,
    uniGraduationCap,
    uniPhone,
    uniMailbox,
    uniUniversity,
    uniFile,
    uniForwadedCall,
    uniChatBubbleUser,
    uniGlobe,
    uniBolt,
    uniGithub,
    uniTwitter,
    uniTelegram,
    uniLinkedin,
    uniLink,
    // end,
]);
