import api from "@api";
import router from "@/router";
import { nullGuid } from "@/EK-core/util/global";
import store from "@/store";
export default {
    state: {
        questions: [],
        socialMedia: [
            { name: "LinkedIn", id: 1, icon: "linkedin-alt" },
            { name: "Telegram", id: 2, icon: "telegram" },
            { name: "Github", id: 3, icon: "github" },
            { name: "YouTube", id: 4, icon: "youtube" },
            { name: "Facebook", id: 5, icon: "facebook-f" },
            { name: "Whatsapp", id: 6, icon: "whatsapp" },
            { name: "Twitter", id: 7, icon: "twitter" },
            { name: "instagram", id: 8, icon: "instagram" },
            { name: "other", id: 9, icon: "link" },
        ],
        questionDto: {
            id: null,
            question: "",
            answer: "",
            date: null,
        },
        trialPeriodDto: {
            id: nullGuid,
            trialPeriod: 0,
        },
    },
    getters: {
        questionsCount(state) {
            return state.questions.length;
        },
    },
    mutations: {
        Get_Questions_List(state, payload) {
            state.questions = payload.map((item) => ({
                ...item,
                isActive: false,
            }));
        },
        Get_All_Questions(state, payload) {
            state.questions = [...payload];
        },
        Set_Question_Details(state, payload) {
            state.questionDto = { ...payload };
        },
        Reset_Question_Dto(state) {
            Object.assign(state.questionDto, {
                id: null,
                question: "",
                answer: "",
                date: null,
            });
        },
        Set_Trail_Period_Dto(state, payload) {
            state.trialPeriodDto = { trialPeriod: payload };
        },
        Reset_Trail_Period_Dto(state) {
            Object.assign(state.questionDto, {
                id: nullGuid,
                trialPeriod: "",
            });
        },
        Toggle_Settings_Question_Status(state, payload) {
            state.questions = state.questions.map((item) =>
                item.id == payload
                    ? { ...item, isActive: !item.isActive }
                    : { ...item }
            );
        },
        Remove_Setting_Answer_Text(state) {
            state.questionDto = { ...state.questionDto, answer: "" };
        },
        Delete_Question(state, payload) {
            state.questions = state.questions.filter(
                (item) => item.id != payload
            );
        },
    },
    actions: {
        getSettings({ commit }) {
            api.get("Setting/GetSettings", ({ data }) => {
                commit("Set_Trail_Period_Dto", data.trialPeriod);
                commit("Get_Questions_List", data.commonQuestion);
                store.commit(
                    "Set_Commercial_Activity_List",
                    data.commercialActivity
                );
            });
        },
        getQuestionsDetails({ commit }, id) {
            api.get(`Setting/GetQuestion?id=${id}`, ({ data }) => {
                commit("Set_Question_Details", data);
            });
        },
        addQuestion({ commit }, payload) {
            api.post(
                "Setting/AddQuestion",
                payload,
                () => {
                    commit("Reset_Question_Dto");
                    router.push("/admin/settings");
                },
                {
                    success: "تم إضافة السؤال بنجاح",
                    error: "فشل إضافة السؤال",
                }
            );
        },
        updateQuestion({ commit }, payload) {
            api.post(
                "Setting/ModifyQuestion",
                payload,
                ({ data }) => {
                    commit("Reset_Question_Dto", data);
                    router.push("/admin/settings");
                },
                {
                    success: "تم تعديل السؤال بنجاح",
                    error: "فشل تعديل السؤال",
                }
            );
        },
        deleteQuestion({ commit }, payload) {
            api.delete(
                `Setting/DeleteQuestion?id=${payload}`,
                () => {
                    commit("Delete_Question", payload);
                },
                {
                    confirm: "هل تريد حذف السؤال؟",
                    success: "تم حذف السؤال بنجاح",
                    error: "فشل حذف السؤال",
                }
            );
        },
        addTrailPeriod({ commit }, payload) {
            api.post(
                "Setting/AddTrialPeriod",
                payload,
                ({ data }) => {
                    commit("Set_Trail_Period_Dto", data.trialPeriod);
                },
                {
                    success: "تم تعديل الفترة الزمنية المدفوعة بنجاح",
                    error: "فشل تعديل الفترة الزمنية المدفوعة",
                }
            );
        },
    },
};
