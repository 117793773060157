import api from "@api";

export default {
    state: {
        homeList: [],
        homeChart: [],
        homeDto: {
            accountCount: "",
            appUserCount: "",
            activityCount: "",
            requsetesCount: "",
            notificationCount: "",
            servicesCount: "",
            jobCount: "",
            activitySubCount: "",
            appUserSubCount: ""
        },
        earningChartData: {
            chartOptions: {
                chart: {
                    type: "donut",
                    toolbar: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                legend: { show: false },
                comparedResult: [2, -3, 8],
                labels: ["App", "Service", "Product"],
                stroke: { width: 0 },
                colors: ["blue", "#B9B9C3"],
                grid: {
                    padding: {
                        right: -20,
                        bottom: -8,
                        left: -20
                    }
                },
                plotOptions: {
                    pie: {
                        startAngle: 0,
                        donut: {
                            labels: {
                                show: true,
                                name: {
                                    offsetY: 15
                                },
                                value: {
                                    offsetY: -15,
                                    formatter(val) {
                                        // eslint-disable-next-line radix
                                        return `${parseInt(val)}`;
                                    }
                                },
                                total: {
                                    show: true,
                                    offsetY: 15,
                                    label: "App",
                                    formatter() {
                                        return "53%";
                                    }
                                }
                            }
                        }
                    }
                },
                responsive: [
                    {
                        breakpoint: 1325,
                        options: {
                            chart: {
                                height: 100
                            }
                        }
                    },
                    {
                        breakpoint: 1200,
                        options: {
                            chart: {
                                height: 120
                            }
                        }
                    },
                    {
                        breakpoint: 1045,
                        options: {
                            chart: {
                                height: 100
                            }
                        }
                    },
                    {
                        breakpoint: 992,
                        options: {
                            chart: {
                                height: 120
                            }
                        }
                    }
                ]
            }
        },
        branchDonutData: {},
        branchDonut: {},
        branchDonuts: {}

    },
    mutations: {
        Get_Home_Dash(state, payload) {
            state.homeList = payload;
        },
        GET_CHART_HOME(state, payload) {
            state.homeChart = payload;
        },
        Update_Branch_Donut_Stats_Data(state, payload) {

            state.branchDonutData = {
                ...state.earningChartData,
                chartOptions: {
                    ...state.earningChartData.chartOptions,
                    colors: ["#459dd3", "#a7d5f2"],
                    labels: ['عدد المستخدمين', 'عدد الحسابات المتبقية'],
                    plotOptions: {
                        ...state.earningChartData.chartOptions.plotOptions,
                        pie: {
                            ...state.earningChartData.chartOptions.plotOptions.pie,
                            donut: {
                                ...state.earningChartData.chartOptions.plotOptions.pie
                                    .donut,
                                labels: {
                                    ...state.earningChartData.chartOptions.plotOptions
                                        .pie.donut.labels,
                                    total: {
                                        ...state.earningChartData.chartOptions
                                            .plotOptions.pie.donut.labels.total,
                                        label: "مستخدم",
                                        formatter: () => payload.appUserCount
                                    }
                                }
                            }
                        }
                    }
                }
            };
        },
        Update_Branch_Stats_Data(state, payload) {

            state.branchDonut = {
                ...state.earningChartData,
                chartOptions: {
                    ...state.earningChartData.chartOptions,
                    colors: ["#ef7c7c", "#f4b7b7"],
                    labels: ['عدد الفعاليات', 'عدد الحسابات المتبقية'],
                    plotOptions: {
                        ...state.earningChartData.chartOptions.plotOptions,
                        pie: {
                            ...state.earningChartData.chartOptions.plotOptions.pie,
                            donut: {
                                ...state.earningChartData.chartOptions.plotOptions.pie
                                    .donut,
                                labels: {
                                    ...state.earningChartData.chartOptions.plotOptions
                                        .pie.donut.labels,
                                    total: {
                                        ...state.earningChartData.chartOptions
                                            .plotOptions.pie.donut.labels.total,
                                        label: "فعالية",
                                        formatter: () => payload.activityCount
                                    }
                                }
                            }
                        }
                    }
                }
            };
        },
        Update_Branch_Sub_Data(state, payload) {

            state.branchDonuts = {
                ...state.earningChartData,
                chartOptions: {
                    ...state.earningChartData.chartOptions,
                    colors: ["#FFFF00", "#55c662"],
                    labels: ['عدد الفعاليات', 'عدد المستخدمين'],
                    plotOptions: {
                        ...state.earningChartData.chartOptions.plotOptions,
                        pie: {
                            ...state.earningChartData.chartOptions.plotOptions.pie,
                            donut: {
                                ...state.earningChartData.chartOptions.plotOptions.pie
                                    .donut,
                                labels: {
                                    ...state.earningChartData.chartOptions.plotOptions
                                        .pie.donut.labels,
                                    total: {
                                        ...state.earningChartData.chartOptions
                                            .plotOptions.pie.donut.labels.total,
                                        label: "اشتراك",
                                        formatter: () => payload.appUserSubCount + payload.activitySubCount
                                    }
                                }
                            }
                        }
                    }
                }
            };
        },
    },

    actions: {
        getHomeDash({ commit }) {
            api.get("HomeDash/GetHomeDash?year=2022", ({ data }) => {
                commit("Get_Home_Dash", data);
                commit("Update_Branch_Donut_Stats_Data", data);
                commit("Update_Branch_Stats_Data", data);
                commit("Update_Branch_Sub_Data", data)
            });
        },
        getChartHome({ commit }) {
            api.get('HomeDash/GetChartDash', ({ data }) => {
                commit("GET_CHART_HOME", data)
            })
        }
    },
};
