import api from "@api";
import { nullGuid } from "@Ekcore/util/global.js";
import router from "@/router";
import store from "@/store";

export default {
    state: {
        orders: {
            allRequests: [],
        },
        notificationOrders: [],
        adsOrders: [],
        jobOrders: [],
        ordersDto: {
            id: null,
            accountName: "",
            comertaialAcitvitytName: "",
            dateCreated: "",
            responseDate: "",
            dashResponse: null,
            name: "",
        },
        activeOrderTab: -1,
        orderStatusList: [
            { id: 1, number: 1, name: "قيد الطلب", color: "primary" },
            { id: 2, number: 2, name: "مرفوض", color: "warning" },
            { id: 3, number: 3, name: "مقبول", color: "success" },
        ],
    },
    getters: {
        ordersIsActive(state) {
            return state.ordersDto.dashResponse == 3;
        },
        ordersIsExpired(state) {
            return state.ordersDto.dashResponse == 4;
        },
        notificationOrdersCount(state) {
            return state.orders.requestNotificationsCount;
        },
        adsOrdersCount(state) {
            return state.orders.requestAdsCount;
        },
        jobOrdersCount(state) {
            return state.orders.requestJobsCount;
        },
    },
    mutations: {
        Get_Orders_List(state, payload) {
            state.orders = { ...payload };

            state.ordersDto = {
                id: null,
                accountName: "",
                comertaialAcitvitytName: "",
                dateCreated: "",
                responseDate: "",
                dashResponse: null,
                name: "",
                activeOrderTab: 0,
            };
        },
        Update_Orders_List(state, payload) {
            state.orders = state.orders.map((el) => {
                if (el.id == payload.id) return payload;
                return el;
            });
        },
        Update_Orders(state, payload) {
            state.ordersDto = {
                ...payload,
                ordersDatesList: [
                    new Date(payload.startDate),
                    new Date(payload.endDate),
                ],
            };
        },
        Update_Orders_Dto_Date(state, payload) {
            payload = payload.split(" ").filter((item) => !item.includes("to"));

            if (payload && payload.length > 0) {
                state.ordersDto = {
                    ...state.ordersDto,
                    startDate: payload[0],
                    endDate: payload[1],
                };

                console.log("state.ordersDto", state.ordersDto);
            }
        },
        Update_Orders_Dto_Activity_Details(state, payload) {
            console.log("paylaod", payload);

            let activity = { ...payload };

            console.log("activity", activity);

            state.ordersDto = {
                ...state.ordersDto,
                commercialName: activity.commercialName,
                cityName: activity.city,
            };

            console.log("deatails", state.ordersDto);
        },
        Reset_Orders_Dto(state) {
            Object.assign(state.ordersDto, {
                id: nullGuid,
                accountId: null,
                startDate: "",
                endDate: "",
                file: "",
                content: "",
                name: "",
                dashResponse: null,
                commercialName: "",
                cityName: "",
                ordersDatesList: [],
            });

            console.log("state.ordersDto", state.ordersDto);
        },
        Add_Orders(state, payload) {
            state.orders = [payload, ...state.orders];
        },
        Delete_Orders_List(state, ids) {
            state.orders.allRequests = state.orders.allRequests.filter(
                (order) => !ids.map(item => item.id).includes(order.id)
            );

            alert(ids.filter(item => item.requestType == 2).length)

            state.orders.requestAdsCount = state.orders.requestAdsCount - ids.filter(item => item.requestType == 1).length 
            
            state.orders.requestNotificationsCount = state.orders.requestNotificationsCount - ids.filter(item => item.requestType == 2).length

            state.orders.requestJobsCount = state.orders.requestJobsCount - ids.filter(item => item.requestType == 3).length
            
        },
        Delete_Ads_Orders_List(state, ids) {
            console.log("ids", ids)
            state.adsOrders = state.adsOrders.filter(
                (ads) => !ids.map(item => item.id).includes(ads.id)
            );

            state.orders.requestAdsCount = state.orders.requestAdsCount - ids.length 
            
        },
        Delete_Notification_Orders_List(state, ids) {
            state.notificationOrders = state.notificationOrders.filter(
                (notification) => !ids.map(item => item.id).includes(notification.id)
            );

            state.orders.requestNotificationsCount = state.orders.requestNotificationsCount - ids.length
        },
        Delete_Job_Orders_List(state, ids) {
            state.jobOrders = state.jobOrders.filter(
                (job) => !ids.map(item => item.id).includes(job.id)
            );

            state.orders.requestJobsCount = state.orders.requestJobsCount - ids.length
        },
        Update_Orders_Image(state, image) {
            if (!image) {
                state.ordersDto = { ...state.ordersDto, file: {} };

                return;
            }

            state.ordersDto = { ...state.ordersDto, file: image };

            console.log("state.ordersDto", state.ordersDto.file);
        },
        Update_Orders_Active_Tab(state, payload) {
            state.activeOrderTab =
                payload == (state.activeOrderTab || payload == -1)
                    ? -1
                    : payload;
        },
        Update_Notification_Orders_List(state, payload) {
            state.notificationOrders = [...payload];
            state.orders = {
                ...state.orders,
                requestNotificationsCount: state.notificationOrders.length,
            };
        },
        Update_Ads_Orders_List(state, payload) {
            state.adsOrders = [...payload];
            state.orders = {
                ...state.orders,
                requestAdsCount: state.adsOrders.length,
            };
        },
        Update_Job_Orders_List(state, payload) {
            state.jobOrders = [...payload];
            state.orders = {
                ...state.orders,
                requestJobsCount: state.jobOrders.length,
            };
        },
    },
    actions: {
        getOrdersList({ commit }) {
            api.get("Requests/GetAll", ({ data }) => {
                commit("Get_Orders_List", data);
            });
        },
        getNotificationOrders({ commit }) {
            api.get("Requests/GetAllNotification", ({ data }) => {
                commit("Update_Notification_Orders_List", data);
            });
        },
        getAdsOrders({ commit }) {
            api.get("Requests/GetAllService", ({ data }) => {
                commit("Update_Ads_Orders_List", data);
            });
        },
        getJobOrders({ commit }) {
            api.get("Requests/GetAllJob", ({ data }) => {
                commit("Update_Job_Orders_List", data);
            });
        },
        fetchOrders({ commit }, payload) {
            api.get(`Service/GetByIdDash?id=${payload}`, ({ data }) => {
                commit("Update_Orders", data);
            });
        },
        fetchAdsOrderById(_, payload) {
            api.get(`Requests/GetByIdService?id=${payload}`, ({ data }) => {
                store.commit("Update_Ads", data);
            });
        },
        fetchNotificationOrderById(_, payload) {
            api.get(
                `Requests/GetByIdNotification?id=${payload}`,
                ({ data }) => {
                    store.commit("Set_Notification_Dto", data);
                }
            );
        },
        fetchJobOrderById(_, payload) {
            api.get(`Requests/GetByIdJob?id=${payload}`, ({ data }) => {
                store.commit("Set_Job_Order_Dto", data);
            });
        },
        addOrders({ commit }, payload) {
            api.post(
                "Service/AddDash",
                payload,
                ({ data }) => {
                    commit("Add_Orders", data);
                    commit("Reset_Orders_Dto");
                },
                {
                    success: "تم إضافة الإعلان بنجاح",
                    error: "فشل إضافة الإعلان",
                }
            );
        },
        updateOrders({ commit }, payload) {
            api.post(
                "Service/ModifyDash",
                payload,
                ({ data }) => {
                    commit("Update_Orders", data);
                },
                {
                    success: "تم تعديل الإعلان بنجاح",
                    error: "فشل تعديل الإعلان",
                }
            );
        },
        deleteRangeAdsOrders({ commit }, payload) {
            
            api.delete(
                "Requests/DeleteRange",
                ({ data }) => {
                    
                    if(data == true) {
                        commit("Delete_Ads_Orders_List", payload);
                    }
                },
                {
                    confirm: "هل تريد حذف الإعلان؟",
                    success: "تم حذف الإعلان بنجاح",
                    error: "فشل حذف الإعلان",
                },
                payload
            );
        },
        deleteRangeAdsOrder({ commit }, payload) {
            
            api.delete(
                "Requests/DeleteRange",
                ({ data }) => {
                    
                    if(data == true) {
                        commit("Delete_Ads_Orders_List", payload);
                        router.push("/admin/orders")
                    }
                },
                {
                    confirm: "هل تريد حذف الإعلان؟",
                    success: "تم حذف الإعلان بنجاح",
                    error: "فشل حذف الإعلان",
                },
                payload
            );
        },
        deleteRangeNotificationOrders({ commit }, payload) {
            api.delete(
                "Requests/DeleteRange",
                ({ data }) => {
                    if (data) {
                        commit("Delete_Notification_Orders_List", payload);
                    }
                },
                {
                    confirm: "هل تريد حذف الإشعار؟",
                    success: "تم حذف الإشعار بنجاح",
                    error: "فشل حذف الإشعار",
                },
                payload
            );
        },
        deleteRangeNotificationOrder({ commit }, payload) {
            api.delete(
                "Requests/DeleteRange",
                ({ data }) => {
                    if (data) {
                        commit("Delete_Notification_Orders_List", payload);
                        router.push("/admin/orders")
                    }
                },
                {
                    confirm: "هل تريد حذف الإشعار؟",
                    success: "تم حذف الإشعار بنجاح",
                    error: "فشل حذف الإشعار",
                },
                payload
            );
        },
        deleteRangeJobOrders({ commit }, payload) {
            api.delete(
                "Requests/DeleteRange",
                ({ data }) => {
                    if (data) {
                        commit("Delete_Job_Orders_List", payload);
                    }
                },
                {
                    confirm: "هل تريد حذف الوظيفة",
                    success: "تم حذف الوظيفة بنجاح",
                    error: "فشل حذف الوظيفة",
                },
                payload
            );
        },
        deleteRangeJobOrder({ commit }, payload) {
            api.delete(
                "Requests/DeleteRange",
                ({ data }) => {
                    if (data) {
                        commit("Delete_Job_Orders_List", payload);
                        router.push("/admin/orders")
                    }
                },
                {
                    confirm: "هل تريد حذف الوظيفة",
                    success: "تم حذف الوظيفة بنجاح",
                    error: "فشل حذف الوظيفة",
                },
                payload
            );
        },
        deleteRangeOrders({ commit }, payload) {
            api.delete(
                "Requests/DeleteRange",
                ({ data }) => {
                    if (data) {
                        commit("Delete_Orders_List", payload);
                    }
                },
                {
                    confirm: "هل تريد حذف الطلب",
                    success: "تم حذف الطلب بنجاح",
                    error: "فشل حذف الطلب",
                },
                payload
            );
        },
        acceptAdsOrder(_, payload) {
            api.post(
                `Requests/AcceptService?id=${payload}`,
                null,
                () => {
                    router.push("/admin/orders");
                },
                {
                    success: "تم قبول طلب الإعلان بنجاح",
                    error: "فشل طلب قبول الإعلان",
                }
            );
        },
        rejectAdsOrder(_, payload) {
            api.post(
                `Requests/RejectService?id=${payload}`,
                null,
                () => {
                    router.push("/admin/orders");
                },
                {
                    success: "تم رفض طلب الإعلان بنجاح",
                    error: "فشل طلب رفض الإعلان",
                }
            );
        },
        acceptNotificationOrder(_, payload) {
            api.post(
                `Requests/AcceptNotification?id=${payload}`,
                null,
                () => {
                    router.push("/admin/orders");
                },
                {
                    success: "تم قبول طلب الإشعار بنجاح",
                    error: "فشل طلب قبول الإشعار",
                }
            );
        },
        rejectNotificationOrder(_, payload) {
            api.post(
                `Requests/RejectNotification?id=${payload}`,
                payload,
                () => {
                    router.push("/admin/orders");
                },
                {
                    success: "تم رفض  طلب الإشعار بنجاح",
                    error: "فشل طلب رفض الإشعار ",
                }
            );
        },
        acceptJobOrder(_, payload) {
            api.post(
                `Requests/AcceptJob?id=${payload}`,
                null,
                () => {
                    router.push("/admin/orders");
                },
                {
                    success: "تم قبول طلب الوظيفة بنجاح",
                    error: "فشل طلب قبول الوظيفة",
                }
            );
        },
        rejectJobOrder(_, payload) {
            api.post(
                `Requests/RejectJob?id=${payload}`,
                payload,
                () => {
                    router.push("/admin/orders");
                },
                {
                    success: "تم رفض  طلب الوظيفة بنجاح",
                    error: "فشل طلب رفض الوظيفة",
                }
            );
        },
        acceptActivityAdsOrder(_, payload) {
            api.post(
                `Requests/AcceptService?id=${payload.id}`,
                null,
                () => {
                    router.push(`/admin/activities/${payload.activityId}`);
                },
                {
                    success: "تم قبول الإعلان بنجاح",
                    error: "فشل طلب قبول الإعلان",
                }
            );
        },
        rejectActivityAdsOrder(_, payload) {
            api.post(
                `Requests/RejectService?id=${payload.id}`,
                null,
                () => {
                    router.push(`/admin/activities/${payload.activityId}`);
                },
                {
                    success: "تم رفض طلب الإعلان بنجاح",
                    error: "فشل طلب رفضس الإعلان",
                }
            );
        },
        acceptActivityNotificationOrder(_, payload) {
            api.post(
                `Requests/AcceptNotification?id=${payload.id}`,
                null,
                () => {
                    router.push(`/admin/activities/${payload.activityId}`);
                },
                {
                    success: "تم قبول طلب الإشعار بنجاح",
                    error: "فشل طلب قبول الإشعار",
                }
            );
        },
        rejectActivityNotificationOrder(_, payload) {
            api.post(
                `Requests/RejectNotification?id=${payload.id}`,
                payload,
                () => {
                    router.push(`/admin/activities/${payload.activityId}`);
                },
                {
                    success: "تم رفض  طلب الإشعار بنجاح",
                    error: "فشل طلب رفض الإشعار ",
                }
            );
        },
        acceptActivityJobOrder(_, payload) {
            api.post(
                `Requests/AcceptJob?id=${payload.id}`,
                null,
                () => {
                    router.push(`/admin/activities/${payload.activityId}`);
                },
                {
                    success: "تم قبول طلب الوظيفة بنجاح",
                    error: "فشل طلب قبول الوظيفة",
                }
            );
        },
        rejectActivityJobOrder(_, payload) {
            api.post(
                `Requests/RejectJob?id=${payload.id}`,
                payload,
                () => {
                    router.push(`/admin/activities/${payload.activityId}`);
                },
                {
                    success: "تم رفض  طلب الوظيفة بنجاح",
                    error: "فشل طلب رفض الوظيفة",
                }
            );
        },
        acceptUserJobOrder(_, payload) {
            api.post(
                `Requests/AcceptJob?id=${payload.id}`,
                null,
                () => {
                    router.push(`/admin/users/details/${payload.userId}`);
                },
                {
                    success: "تم قبول طلب الإشعار بنجاح",
                    error: "فشل طلب قبول الإشعار",
                }
            );
        },
        rejectUserJobOrder(_, payload) {
            api.post(
                `Requests/RejectJob?id=${payload.id}`,
                payload,
                () => {
                    router.push(`/admin/users/details/${payload.userId}`);
                },
                {
                    success: "تم رفض  طلب الوظيفة بنجاح",
                    error: "فشل طلب رفض الوظيفة",
                }
            );
        },
        acceptUserNotificationOrder(_, payload) {
            api.post(
                `Requests/AcceptNotification?id=${payload.id}`,
                null,
                () => {
                    router.push(`/admin/users/details/${payload.userId}`);
                },
                {
                    success: "تم قبول طلب الإشعار بنجاح",
                    error: "فشل طلب قبول الإشعار",
                }
            );
        },
        rejectUserNotificationOrder(_, payload) {
            api.post(
                `Requests/RejectNotification?id=${payload.id}`,
                payload,
                () => {
                    router.push(`/admin/users/details/${payload.userId}`);
                },
                {
                    success: "تم رفض  طلب الوظيفة بنجاح",
                    error: "فشل طلب رفض الوظيفة",
                }
            );
        },
    },
};
