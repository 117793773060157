import api from "@api";
import router from "@/router"

export default {
    state: {
        jobs: [],

        jobOrderDto: {
            id: "",
            commercialName: "",
            cityName: "",
            jobTitle: "",
            deadLineDate: "",
            responseDate: null,
            submitCount: 1,
            isAvailable: true,
            senderId: "",
            description: "",
            extraUrl: "",
            submitsCount: 0,
            submites: [
                {
                    jobInfoId: "",
                    name: "test user",
                    dateTime: "",
                    location: "",
                },
            ],
        },
        jobTypes: [
            { name: "عن بعد", id: 1 },
            { name: "دوام كامل", id: 2 },
            { name: "دوام جزئي", id: 3 },
        ],
        jobDto: {
            cityName: "",
            comertialAcitvityName: "",
            deadLineDate: "",
            description: "",
            extraUrl: "",
            id: "",
            isAvailable: "",
            jobTitle: "",
            responseDate: "",
            senderId: "",
            submitCount: 0,
            submites: [],
            submitsCount: 0,
        },

        submitDto: {
            contactInfo: {
                email: "",
                phoneNumber: null,
                gender: 0,
                birthDate: "",
            },
            cvUrl: "",
            graduationDate: "",
            languages: "tes",
            lastScientificDegree: "",
            location: "",
            name: "",
            schoolName: "",
            skills: "",
            socialMedias: [],
        },
    },
    mutations: {
        Set_Jobs(state, payload) {
            state.jobs = payload;
        },
        Add_Job(state, payload) {
            state.jobs.push(payload);
        },
        Set_Job_Order_Dto(state, payload) {
            state.jobOrderDto = {
                ...payload,
                deadLineDate: new Date(
                    payload.deadLineDate
                ).toLocaleDateString(),
            };
        },
        Delete_Jobs(state, payload) {
            state.jobs = state.jobs.filter(item => ! payload.includes(item.id))
        },
        Reset_Job_Order_Dto(state) {
            Object.assign(state.jobOrderDto, {
                id: "",
                commercialName: "",
                cityName: "",
                jobTitle: "",
                deadLineDate: "",
                responseDate: null,
                submitCount: 1,
                isAvailable: true,
                senderId: "",
                description: "",
                extraUrl: "",
                submitsCount: 0,
                submites: [
                    {
                        jobInfoId: "",
                        name: "test user",
                        dateTime: "",
                        location: "",
                    },
                ],
            });
        },
        Update_Job_Order_Dto_Activity_Details(state, payload) {
            let activity = { ...payload };

            state.jobOrderDto = {
                ...state.jobOrderDto,
                commercialName: activity.commercialName,
                location: activity.location,
            };
        },
        Get_Jobs(state, payload) {
            state.jobs = payload;
        },
        Set_Job_Dto(state, payload) {
            state.jobDto = { ...payload, subStatuses:
                new Date(payload.deadLineDate) >= Date.now()
                    ? 1
                    : 2,  
            };
        },
        Map_User_Job_To_Job_Order_Dto(state, payload) {
            console.log("payload", payload);
            state.jobOrderDto = {};
        },
        Set_Job_Submit(state, payload) {
            state.submitDto = { ...payload };
            console.log(state.submits);
        },
    },
    actions: {
        getJobsSubmit({ commit }, id) {
            api.get(`job/GetJobSubmitDash?jobInfo=${id}`, ({ data }) => {
                commit("Set_Job_Submit", data);
                console.log(data);
                console.log(id);
            });
        },
        getJobs({ commit }) {
            api.get("job/GetAllDash", ({ data }) => {
                commit("Set_Jobs", data);
            });
        },
        getByIdDash({ commit }, id) {
            api.get(`job/GetByIdDash?id=${id}`, ({ data }) => {
                commit("Set_Job_Dto", data);
                console.log(data);
            });
        },
        editJobDto(__, payload) {
            api.post(
                `job/ModifyDash`,
                {
                    id: payload.id,
                    discription: payload.description,
                    extraUrl: payload.extraUrl,
                    name: payload.comertialAcitvityName,
                    jobType: payload.jobType,
                    deadLineDate: payload.deadLineDate,
                },
                ({ data }) => {
                    console.log(data);
                    router.push("/admin/jobs")
                }
            );
        },
        addJob({ commit }, payload) {
            api.post(
                "job/AddDash",
                payload,
                ({ data }) => {
                    commit("Add_Job", data);
                },
                {
                    success: "تمت إضافة الوظيفة بنجاح",
                    error: "فشلت إضافة الوظيفة..يرجى إعادة المحاولة",
                }
            );
        },
        deleteJobs({ commit }, ids) {

            console.log("ids", ids)
            api.delete(
                "Job/DeleteRangeDash",
                () => {
                    commit("Delete_Jobs", ids);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف الاشتراكات المحددة",
                    success: "تم حذف الاشتراكات بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                ids
            );
        },
    },
};
