import useJwt from "./jwt/useJwt";
import jwtDecode from "jwt-decode";

export const RoleClime =
    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";

import { Admin, Editor, SuperAdmin } from "@/router";
/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
    return (
        localStorage.getItem("userData") &&
        localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
    );
};

export const getUserData = () => JSON.parse(localStorage.getItem("userData"));
export const getUserRoles = () => {
    if (isUserLoggedIn()) {
        return jwtDecode(
            localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
        )[RoleClime];
    } else {
        return null;
    }
};

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
    if (userRole === Admin || userRole === Editor || userRole === SuperAdmin)
        return "/";
    return "/login";
};

export const getCustomerId = () => {
    const token = localStorage.getItem(
        useJwt.jwtConfig.websiteStorageTokenKeyName
    );
    const decoded = token !== null ? jwtDecode(token) : null;

    return decoded !== null
        ? decoded[
              "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
          ]
        : 0;
};
