import api from "@api";

export default {
    state: {
        cities: [],
        countries: [],
    },

    mutations: {
        Get_All_Locations(state, payload) {
            state.cities = payload;
        },
        Get_All_Countries(state, payload) {
            state.countries = payload;
        },
    },
    actions: {
        getCities({ commit }) {
            api.get("Location/GetAllCities", ({ data }) => {
                commit("Get_All_Locations", data);
            });
        },
        getCountries({ commit }) {
            api.get("Location/GetAllCountries", ({ data }) => {
                commit("Get_All_Countries", data);
            });
        },
    },
};
